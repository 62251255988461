import store from '@common/redux/store';
import {
  ceil,
  cloneDeep,
  find,
  get,
  isArray,
  isEmpty,
  isNil,
  isObject,
  isString,
  isUndefined,
  random,
  round,
} from 'lodash';
if (typeof Intl === 'undefined') {
  require('intl');
  require('intl/locale-data/jsonp/en');
  require('intl/locale-data/jsonp/en-IN');
}

import {
  BINDING_SELECTOR_TYPE,
  DATE_FORMAT,
  DATE_TIME_FORMAT_CANVAS,
  ONE_DAY,
  pathComponents,
} from '@common/constants/shared';
import { getValueFields } from '@common/redux/selectors/formInput';
import moment from 'moment';
import {
  bindingType,
  formatValueDate,
  formatValueNumber,
  getDefaultImage,
  getValueBindingInput,
  getValueBindingPlugin,
  typeRelation,
} from '../functions';
import {
  flatRecord,
  flatSource,
  flattenObj,
  getAverageValue,
  getCountValue,
  getInitValueBindingRecord,
  getSelectValueSelector,
  getSumValue,
  getValueBindingData,
  getValueFieldRelation,
  getValueFileBinding,
  getValueMax,
  getValueMin,
  getValueMinMax,
} from './function';
import { handlePermissionField } from '@common/hooks/usePermission';
import { getFieldTable, getLastedRecordCreate } from '../database';
import { excuteRoundRelationship } from './helps';
import { IRecord } from '@common/types/database';

const Conversions: Record<string, string> = {
  '&nbsp;': '\u00A0',
  '&gt;': '>',
  '&lt;': '<',
  '&amp;': '&',
} as const;

const getBaseLog = (x: number, y: number) => {
  return Math.log(x) / Math.log(y);
};

const getOperand = (
  val: Record<string, any> | number | string | Record<string, any>[],
  record: any,
  isAction: boolean | undefined,
  itemIndex?: number,
  currentId?: string
) => {
  let value = isArray(val)
    ? handleGetFormula(val, record, isAction, itemIndex, currentId)
    : isObject(val)
    ? handleGetValue(
        {
          ...val,
          visibility: true,
        },
        record,
        isAction,
        itemIndex,
        currentId
      )
    : +val;

  value = value && isNaN(+value) ? moment(value).valueOf() / ONE_DAY : +value;

  return value;
};

const switchOperator = (
  operator: string,
  a: Record<string, any> | number | string | Record<string, any>[],
  b: Record<string, any> | number | string | Record<string, any>[],
  record: any,
  isAction: boolean | undefined,
  itemIndex?: number,
  currentId?: string
) => {
  const first: any = getOperand(a, record, isAction, itemIndex, currentId);

  const second: any = getOperand(b, record, isAction, itemIndex, currentId);

  switch (operator) {
    case 'negative':
      return -first;
    case 'sum':
      return first + second;
    case 'division':
      return first / second;
    case 'product':
      return first * second;
    case 'subtraction':
      return first - second;
    case 'log10':
      return second ? getBaseLog(first, second) : Math.log10(first);
    case 'random':
      return random(first, second);
    case 'pow':
      return Math.pow(first, second);
    case 'sqrt':
      return Math.sqrt(first);
    case 'abs':
      return Math.abs(first);
    case 'floor':
      return !isNil(second) && !isNaN(second)
        ? second * first < 0
          ? undefined
          : Math.floor(first / second) * second
        : Math.floor(first);
    case 'parseInt':
      return Math.floor(first);
    case 'round':
      return round(first, second);
    default:
      return 0;
  }
};

export const getBindingValue = (
  source: any,
  params: any,
  initialValue: any,
  isAction?: boolean | undefined,
  itemIndex?: number,
  currentId?: string
) => {
  if (!source) return '';
  if (typeof source !== 'object') return source;

  let _initialValue;

  const state: any = store.getState();

  const objectBinding = state.formInputs.values;

  const authProfile = state.auth.profile;

  const listTable = state.database.dataSource;

  const sourceFlatted = flatSource(source);

  const childSourceType = source?.source?.type;

  const childTableId = source?.source?.tableId;

  const childFieldId = source?.source?.fieldId;

  const recordOutput = flatRecord(listTable, childTableId);

  const isRelationShipMany = typeRelation.includes(childSourceType);

  const { selector } = get(sourceFlatted, '0');

  const currentRecord = state.database.currentRecord;

  const currentLocation = state.database.currentLocation;

  const listDatabases = state.database.database;

  const mainTable = find(listDatabases, {
    databaseUuid: source?.source?.tableId,
  });

  const isThirdParty = mainTable && mainTable.isThirdParty;

  if (isThirdParty) {
    return externalBinding(source, mainTable, initialValue, itemIndex);
  }

  const handleInitialValue = ({
    response,
    collection,
  }: {
    response: any;
    collection?: any;
  }) => {
    return handlePermissionField({
      response,
      collection: collection ? collection : mainTable,
      visibility: source?.visibility,
    });
  };

  const convertInitialValue = handleInitialValue({
    response: initialValue,
  });

  const initValBinding = getInitValueBindingRecord(
    source,
    selector?.type,
    [
      bindingType.COUNT,
      bindingType.SUM,
      bindingType.AVERAGE,
      bindingType.MIN,
      bindingType.MAX,
      bindingType.MIN_MAX,
      bindingType.HAS_MANY_ONE_RELATIONSHIP,
      bindingType.MANY_TO_MANY_RELATIONSHIP,
      bindingType.BE_LONG_RELATIONSHIP,
    ].includes(source.type)
      ? initialValue
      : convertInitialValue,
    childTableId
  );

  if (source?.fieldId === 'lineId') {
    return authProfile?.lineid ? authProfile?.lineid : '';
  }

  if (source?.fieldId === 'np_master_uid') {
    return authProfile?.np_master_uid ? authProfile?.np_master_uid : '';
  }

  if (source?.fieldId === 'appleUser') {
    return authProfile?.apple_user ? authProfile?.apple_user : '';
  }

  switch (source.type) {
    case bindingType.DATA:
      return getValueBindingData(
        selector?.type,
        authProfile,
        source,
        listTable,
        convertInitialValue,
        isAction
      );

    case bindingType.INPUT:
      return getValueBindingInput(source, itemIndex, initValBinding);
    case bindingType.PLUGIN:
      return getValueBindingPlugin(source, itemIndex, initValBinding);

    case bindingType.HAS_MANY_ONE_RELATIONSHIP:
    case bindingType.MANY_TO_MANY_RELATIONSHIP:
    case bindingType.BE_LONG_RELATIONSHIP:
      return getValueFieldRelation(
        source,
        initValBinding,
        listTable,
        source?.fieldId,
        isAction,
        selector?.type,
        sourceFlatted,
        currentId
      );

    case bindingType.COUNT:
      if (selector?.type === BINDING_SELECTOR_TYPE.SELECT_VALUE_SELECTOR) {
        const selectedItem = getSelectValueSelector({
          source,
          sourceFlatted,
          listTable,
          objectBinding,
        });

        return selectedItem ? selectedItem.length : null;
      }

      return getCountValue(
        isRelationShipMany,
        recordOutput,
        initValBinding,
        childFieldId,
        source,
        listTable,
        currentRecord
      );

    case bindingType.SUM:
      return getSumValue(
        source,
        isRelationShipMany,
        recordOutput,
        initValBinding,
        childFieldId,
        listTable,
        currentRecord
      );

    case bindingType.AVERAGE:
      return getAverageValue(
        source,
        isRelationShipMany,
        recordOutput,
        initValBinding,
        childFieldId,
        currentRecord
      );

    case bindingType.MIN:
      return getValueMin(
        source,
        isRelationShipMany,
        recordOutput,
        initValBinding,
        childFieldId,
        currentRecord
      );

    case bindingType.MAX:
      return getValueMax(
        source,
        isRelationShipMany,
        recordOutput,
        initValBinding,
        childFieldId,
        currentRecord
      );

    case bindingType.MIN_MAX:
      return getValueMinMax(
        source,
        isRelationShipMany,
        recordOutput,
        initValBinding,
        childFieldId,
        currentRecord
      );

    case bindingType.DATE_TIME:
      const date =
        (source?.format?.type && source?.options?.startOfDay) ||
        (!source?.format?.type && !isEmpty(source.options))
          ? moment().startOf('day')
          : moment();

      if (source?.options?.offset) {
        const duration = moment.duration(source?.options?.offset, 'days');

        date.add(duration.asMilliseconds(), 'ms');
      }
      return source?.format?.type
        ? date.format(DATE_FORMAT.DATE_TIME_FORMAT)
        : date.format(DATE_FORMAT.DATE_LABEL_FORMAT);

    case bindingType.CURRENT_LOCATION:
      const current =
        (source?.type && source?.options?.geometry) ||
        (!source?.type && !isEmpty(source.options))
          ? currentLocation.lat
          : currentLocation.lng;
      return current;

    case bindingType.IMAGE_BINDING:
      return { ...source, url: source.binding };

    case bindingType.FIELD:
      if (source.source.dataType === 'image') {
        const { selector, tableId } = get(sourceFlatted, '0');

        // const valueField = initValBinding?.record
        //   ? initValBinding?.record
        //   : initValBinding;

        const collection = listDatabases.find(
          (item: any) => item.databaseUuid === tableId
        );

        const valueField = handleInitialValue({
          response: initValBinding,
          collection,
        });

        //take the value in case one
        const a = get(valueField, source?.fieldId, {});

        //take the value in case one
        const b = get(valueField, source?.source?.fieldId, {});

        const valueBind = !isEmpty(a) ? a : b;

        if (!isEmpty(valueBind)) {
          return (
            get(valueField, source.fieldId) ||
            get(valueField, source?.source?.fieldId, {})[source.fieldId]
          );
        }

        if (selector?.type === BINDING_SELECTOR_TYPE.CREATED_OBJECT) {
          const created = getLastedRecordCreate(tableId);

          return get(created, `record.${childFieldId}.${source?.fieldId}`);
        } else if (
          selector?.type === BINDING_SELECTOR_TYPE.SELECT_VALUE_SELECTOR
        ) {
          const fieldName = get(source, 'fieldId');

          const selectedItem = getSelectValueSelector({
            source,
            sourceFlatted,
            listTable,
            objectBinding,
          });

          return selectedItem ? get(selectedItem, fieldName) : null;
        }

        const flattenSource = flattenObj(source);

        //relationship binding
        return excuteRoundRelationship(source, flattenSource, initialValue);
      }

      if (source.source.dataType === 'file') {
        const { selector, tableId } = get(sourceFlatted, '0');

        const collection = listDatabases.find(
          (item: any) => item.databaseUuid === tableId
        );

        const valueField = handleInitialValue({
          response: initValBinding,
          collection,
        });

        if (selector?.type === BINDING_SELECTOR_TYPE.SELECT_VALUE_SELECTOR) {
          const fieldName = get(source, 'fieldId');

          const selectedItem = getSelectValueSelector({
            source,
            sourceFlatted,
            listTable,
            objectBinding,
          });

          return selectedItem ? get(selectedItem, fieldName) : null;
        } else if (selector?.type === BINDING_SELECTOR_TYPE.CREATED_OBJECT) {
          const created = getLastedRecordCreate(tableId);

          return get(created, `record.${childFieldId}.${source?.fieldId}`);
        }

        return getValueFileBinding(
          source,
          // initValBinding,
          valueField,
          authProfile,
          listTable,
          selector?.type,
          currentRecord[tableId]?.record,
          collection
        );
      }

      if (source.source.dataType === 'object') {
        const fieldIdAuth = source.fieldId;

        const { objectId } = source;

        const { selector, tableId } = get(sourceFlatted, '0');

        const fieldBinding = getFieldTable(tableId, fieldIdAuth);

        const fieldType = get(fieldBinding, 'type', '');

        const isData = !!selector;

        if (!isData) {
          return get(objectBinding, objectId);
        }

        const targetedId = get(currentRecord[tableId], '_id');

        const targetedRecord =
          !isEmpty(targetedId) && !isEmpty(tableId)
            ? find(listTable[tableId], { _id: targetedId })
            : null;

        switch (selector.type) {
          case BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR: {
            if (source?.source?.type === bindingType.BE_LONG_RELATIONSHIP) {
              return getValueFieldRelation(
                source,
                authProfile,
                listTable,
                source.fieldId,
                isAction,
                undefined
              );
            }

            _initialValue = authProfile;
            break;
          }
          case BINDING_SELECTOR_TYPE.SELECT_VALUE_SELECTOR: {
            if (source?.source?.type === 'belongsToRelation') {
              return getValueFieldRelation(
                source,
                initValBinding,
                listTable,
                source?.fieldId,
                isAction
              );
            }
            const objectId = selector.selectObjectId;

            const tableSource = get(listTable, tableId);
            const selectedItem = find(tableSource, {
              _id: get(objectBinding, objectId),
            });

            if (isNil(selectedItem)) {
              _initialValue = get(objectBinding, objectId);
            } else {
              _initialValue = selectedItem;
            }
            break;
          }

          case BINDING_SELECTOR_TYPE.CREATED_OBJECT: {
            const { tableId } = source.source;
            const { record } = getLastedRecordCreate(tableId);
            _initialValue = record;
            break;
          }

          case BINDING_SELECTOR_TYPE.ROUTE_PARAM_SELECTOR:
            if (typeRelation.includes(source?.source?.type)) {
              if (source?.source?.type === 'belongsToRelation') {
                return getValueFieldRelation(
                  source,
                  targetedRecord,
                  listTable,
                  source?.fieldId,
                  isAction
                );
              }
              return null;
            }

            _initialValue = targetedRecord;
            break;

          case BINDING_SELECTOR_TYPE.LIST_ITEM_SELECTOR:
            const { listObjectId } = selector;
            let sourceData: IRecord = initialValue;

            // if (currentId && currentId !== listObjectId) {
            //   sourceData = initialValue.parentRecord;
            // }

            if (typeRelation.includes(source?.source?.type)) {
              if (source?.source?.type === 'belongsToRelation') {
                return getValueFieldRelation(
                  source,
                  sourceData || targetedRecord,
                  listTable,
                  source?.fieldId,
                  isAction
                );
              }

              return null;
            } else {
              sourceData =
                source?.source?.tableId ===
                initialValue?.parentRecord?.databaseId
                  ? initialValue.parentRecord
                  : initialValue;
            }

            const currentBindValue = get(sourceData, 'source.source.tableId');
            _initialValue = !isEmpty(currentBindValue)
              ? currentBindValue
              : sourceData;
            break;

          default:
            _initialValue = initialValue;
            break;
        }

        // const isRecord = _initialValue?.record;
        const isCurrentRecord = get(_initialValue, `${childTableId}`, {});

        const result = handleInitialValue({
          response: !isEmpty(isCurrentRecord) ? isCurrentRecord : _initialValue,
        });

        // const value = isRecord
        //   ? get(_initialValue, `record.${fieldIdAuth}`, '')
        //   : !isEmpty(isCurrentRecord)
        //   ? get(_initialValue, `${childTableId}.record.${fieldIdAuth}`, '')
        //   : get(_initialValue, fieldIdAuth, '');

        const value = get(result, fieldIdAuth, '');

        if (fieldIdAuth === 'sortedItemNumber') {
          if (
            selector.type === BINDING_SELECTOR_TYPE.LIST_ITEM_SELECTOR &&
            !isNil(itemIndex)
          ) {
            return itemIndex + 1;
          }
        }

        if (['createdAt', 'updatedAt'].includes(fieldIdAuth)) {
          const getRecord = get(_initialValue, `${fieldIdAuth}`);
          if (
            _initialValue?.databaseId &&
            (!getRecord || source.source.tableId !== _initialValue.databaseId)
          )
            return;

          return moment(getRecord).format(DATE_FORMAT.DATE_LABEL_FORMAT);
        }

        //TODO: relationship fields permission
        if (source.source?.type === 'belongsToRelation') {
          if (source.source?.source?.type === 'belongsToRelation') {
            const newInit = find(listTable[source.source.tableId], (item) => {
              return item.record[source?.fieldId];
            });

            const newFieldId = getValueFieldRelation(
              source.source,
              _initialValue,
              listTable,
              source?.fieldId
            );

            return getValueFieldRelation(
              source,
              newInit,
              listTable,
              newFieldId
            );
          }

          return getValueFieldRelation(
            source,
            _initialValue,
            listTable,
            source?.fieldId
          );
        }

        if (['number'].includes(fieldType)) return value;

        if (!value && typeof value !== 'boolean') return null;

        if (isEmpty(fieldType)) {
          const isValidDate = moment(value, true).isValid();

          return isValidDate
            ? moment(value).format(DATE_FORMAT.DATE_LABEL_FORMAT)
            : value;
        }

        if (['date', 'dateOnly'].includes(fieldType)) {
          if (fieldType === 'dateOnly') {
            return moment(value).format(DATE_FORMAT.DATE_ONLY);
          } else {
            return moment(value).format(DATE_FORMAT.DATE_LABEL_FORMAT);
          }
        }

        return value;
      } else {
        return (
          (source.fieldId && get(initialValue, source.fieldId, null)) ||
          get(
            get(initialValue, source.source.fieldId, null),
            source.fieldId,
            null
          )
        );
      }

    case bindingType.CUSTOMACTION:
      const currentCustomAction = state.customAction.actionCustomActions;
      const objCus = currentCustomAction.find((obj: any) => {
        if (obj.customActionId === source.customActionId) {
          return obj;
        }
      });
      let data = null;
      if (objCus?.data && source?.key) {
        data = get(objCus.data, source.key);
      }
      return data;
  }
};

const externalBinding = (
  source: any,
  mainTable: any,
  initValBinding: any,
  itemIndex?: number
): any => {
  const state: any = store.getState();

  const externalCollections = state.externalCollection.externalCollections;

  const externalIndex = state.externalCollection.indexExternal;

  const bindingRecord = state.externalCollection.externalRecord;

  const external = find(externalCollections, {
    databaseUuid: source?.source?.tableId,
  });

  const externalDatas = external?.data;

  const field = mainTable.fields.find(
    (fieldInfo: any) => fieldInfo.fid === source?.fieldId
  );
  let arr: number[] = [];

  if (source.type !== bindingType.FIELD) {
    for (let index = 0; index < externalDatas?.length; index++) {
      arr.push(get(externalDatas, field?.key?.replace('[0]', `[${index}]`)));
    }
  }
  const objKey = field?.key?.replace('[0].', '');

  switch (source.type) {
    case bindingType.COUNT:
      return externalDatas?.length || '';

    case bindingType.SUM:
      return arr.reduce((a, b) => a + b, 0) || 0;

    case bindingType.AVERAGE:
      return arr.reduce((a, b) => a + b, 0) / arr?.length || 0;

    case bindingType.MIN:
      return Math.min(...arr) || 0;

    case bindingType.MAX:
      return Math.max(...arr) || 0;

    case bindingType.MIN_MAX:
      const min: number = Math.min(...arr) || 0;

      const max: number = Math.max(...arr) || 0;

      return ceil(min / max, 3);

    case bindingType.FIELD:
      if (source.source.dataType !== 'object') {
        return '';
      }
      const type = source?.source?.selector?.type;
      // change input data && get data when binding click list to other page
      if (
        type === BINDING_SELECTOR_TYPE.ROUTE_PARAM_SELECTOR &&
        bindingRecord &&
        bindingRecord.length &&
        objKey
      ) {
        const item = bindingRecord.find(
          (data: any) => data.databaseUuid === mainTable?.databaseUuid
        );

        return get(item?.externalRecord, objKey) || '';
      }

      if (type === BINDING_SELECTOR_TYPE.SELECT_VALUE_SELECTOR) {
        const valuesInputs = state.formInputs.values;
        const selectObjectId = source?.source?.selector?.selectObjectId;

        return (
          get(
            externalDatas,
            field?.key?.replace('[0]', `[${valuesInputs[selectObjectId]}]`)
          ) || ''
        );
      }

      // get data from list record
      if (!isEmpty(initValBinding) && objKey) {
        return get(initValBinding, objKey) || '';
      }

      if (field && field?.key && external) {
        // get data when binding click list to other page
        if (itemIndex === undefined) {
          return get(bindingRecord, objKey) || '';
        }

        // binding list data click list in same page
        return (
          get(externalDatas, field?.key?.replace('[0]', `[${itemIndex}]`)) ||
          get(externalDatas, field?.key) ||
          ''
        );
      } else {
        if (itemIndex !== undefined) {
          return external?.data[itemIndex] || '';
        }
        return external?.data[externalIndex] || '';
      }
  }
};

export const getBindingRecord = (
  source: any,
  params: any,
  initialValue: any,
  isAction?: boolean | undefined,
  itemIndex?: number,
  currentId?: string
) => {
  if (!source) return '';
  if (typeof source !== 'object') return source;

  const sourceFlatted = flatSource(source);

  // sourceFlatted.length === 1 when is bind datetime, binding 1 condition,please not remove it
  const sourceMapper =
    sourceFlatted.length === 1
      ? sourceFlatted
      : sourceFlatted.filter(
          (item: any) => item.datasourceId || item?.objectId
        );

  const resp = sourceMapper.map((sourceItem: any) => {
    return getBindingValue(
      // assign({ source: sourceItem }, { ...source }),
      {
        source: sourceItem,
        ...source,
      },
      params,
      initialValue,
      isAction,
      itemIndex,
      currentId
    );
  });

  const indexValue = sourceMapper.length - 1;

  return resp[indexValue];
};

const handleGetFormula = (
  val: any,
  record?: any,
  isAction?: boolean | undefined,
  itemIndex?: number,
  currentId?: string
) => {
  const [a, b, c] = val;

  const formulaValue = isString(val)
    ? val
    : switchOperator(a, b, c, record, isAction, itemIndex, currentId);

  return formulaValue;
};

export const handleGetValue = (
  val: any,
  record?: any,
  isAction?: boolean | undefined,
  itemIndex?: number,
  currentId?: string
): any => {
  if (!val) return null;

  const state: any = store.getState();

  if (isString(val)) {
    return val.replace(/&(nbsp|amp|quot|lt|gt);/g, (i) => Conversions[i]);
  }

  if (typeof val === 'number') {
    return val;
  }

  try {
    const valueInput = getValueFields(state);

    if (val?.type === 'health') {
      return 0;
    }

    if (val?.type === 'formula') {
      if (!val.valid) return;
      let formulaValue: number;

      if (val?.parsedFormula?.length && isArray(val?.parsedFormula)) {
        formulaValue = +handleGetFormula(
          val.parsedFormula,
          record,
          isAction,
          itemIndex,
          currentId
        );
      } else {
        formulaValue = val.formula
          .map((o: any) => {
            if (['number'].includes(o?.source?.dataType)) {
              return getBindingRecord(
                o.source,
                {},
                record,
                isAction,
                itemIndex,
                currentId
              );
            } else {
              return handleGetValue(o, record, isAction, itemIndex, currentId);
            }
          })
          .join('');
      }

      if (formulaValue && isNaN(+formulaValue)) {
        formulaValue = moment(formulaValue).valueOf() / ONE_DAY;
      }
      if (val?.format?.type) {
        if (Object.values(DATE_TIME_FORMAT_CANVAS).includes(val?.format?.type))
          return formatValueDate(
            val.format.type,
            new Date(formulaValue * ONE_DAY)
          );

        formulaValue = round(formulaValue, 9);
        return formatValueNumber(val.format.type, formulaValue);
      }

      const countDecimals = (value: number) => {
        if (Math.floor(value) === value) return 0;
        return value.toString().split('.')[1]?.length || 0;
      };

      const count = countDecimals(+formulaValue);

      if (isNil(formulaValue) || isUndefined(formulaValue)) return;

      //FIX ME: Because date and date time also have a type of formula, so when addition operator in front, it will be NaN
      // const parseFormulaValue = ['date', 'dateOnly'].includes(
      //   val?.outputDataType
      // )
      //   ? formulaValue
      //   : +formulaValue;

      return count > 9 ? (+formulaValue).toFixed(9) : formulaValue;
    }

    if (val?.type === 'input') {
      // input in CustomList
      // const isList = !isUndefined(itemIndex) && itemIndex >= 0;

      const objectId = val?.objectId;

      const valueObject = valueInput[objectId] || null;

      return isAction && !isEmpty(valueObject) && isString(valueObject)
        ? { url: valueObject }
        : valueObject;
    } else {
      const sourceBinding = [
        'image',
        'file',
        'boolean',
        'list',
        'object',
      ].includes(val?.dataType)
        ? val
        : isString(val?.source)
        ? val?.source
        : {
            ...val?.source,
            ...(!isUndefined(val?.source) && {
              visibility: val?.visibility,
            }),
          };

      const bindingValue = getBindingRecord(
        sourceBinding,
        {},
        record,
        isAction,
        itemIndex,
        currentId
      );

      if (sourceBinding?.format?.type || val?.format?.type) {
        if (sourceBinding?.dataType === 'number') {
          return formatValueNumber(
            sourceBinding?.format?.type || val?.format?.type,
            bindingValue
          );
        }
      }

      return bindingValue;
    }
  } catch (e) {
    console.log(`e`, e);
  }
};

export const getTextBinding = (
  value: any,
  record?: any,
  isAction?: boolean | undefined,
  itemIndex?: number,
  currentId?: string,
  customDate?: boolean
) => {
  if (isString(value)) {
    return value;
  }
  if (!Array.isArray(value)) {
    if (typeof value === 'object') {
      return handleGetValue(value, record, isAction, itemIndex, currentId);
    }
    return value;
  } else {
    const defaultValueProcessed = value.map((val) => {
      const bindingValue = handleGetValue(
        val,
        record,
        isAction,
        itemIndex,
        currentId
      );

      if (customDate) return bindingValue;

      if (val?.source?.format?.type || val?.format?.type) {
        if (
          val?.source?.dataType === 'date' ||
          val?.source?.dataType === 'dateOnly'
        ) {
          return formatValueDate(
            val?.source?.format?.type || val?.format?.type,
            bindingValue
          );
        }
      }

      return bindingValue;
    });

    return defaultValueProcessed.join('');
  }
};

const getUrlImageBinding = (imageBinding: any, record: any) => {
  const getSoure: any = imageBinding.filter(
    (item: Record<string, any>) => item.id
  );

  if (getSoure) {
    let newArray = [];

    for (let i = 0; i < imageBinding.length; i++) {
      if (typeof imageBinding[i] === 'object') {
        imageBinding[i] = getBindingRecord(imageBinding[i].source, {}, record);

        newArray.push(imageBinding[i]);
      } else {
        newArray.push(imageBinding[i]);
      }
    }
    const result = newArray.join('');

    return !isEmpty(newArray) ? result : null;
  }

  return imageBinding[0];
};

export const getImageBinding = (imageBinding: any, record: any) => {
  //form url
  if (typeof imageBinding === 'string') return imageBinding;

  //binding url
  if (isArray(imageBinding)) {
    const cloneImageBinding = cloneDeep(imageBinding);
    const result = getUrlImageBinding(cloneImageBinding, record);

    return result;
  }

  //upload image
  if (imageBinding?.binding && typeof imageBinding?.binding === 'string')
    return imageBinding;

  if (imageBinding?.binding && isArray(imageBinding?.binding)) {
    const getImageBinding: any = imageBinding?.binding;
    const cloneImageBinding = cloneDeep(getImageBinding);

    const result = getUrlImageBinding(cloneImageBinding, record);

    return { binding: result };
  }

  //binding data form database
  const imageUrl = getBindingRecord(imageBinding?.binding?.source, {}, record);

  return imageUrl
    ? {
        binding: typeof imageUrl === 'string' ? imageUrl : imageUrl?.url,
        imageType: imageBinding.imageType,
      }
    : getDefaultImage(imageBinding?.binding?.options, imageBinding?.imageType);
};

const getLeftSectionValue = (value: any, record: any) => {
  let result;
  switch (value.type) {
    case 'image':
    case 'avatar':
      result = {
        ...value,
        imageUrl:
          value.imageUrl?.imageType === 'url' ||
          value.imageUrl?.imageType === 'uploaded'
            ? {
                binding:
                  getImageBinding(value.imageUrl?.imageUrl, record).binding ||
                  getDefaultImage(
                    value.imageUrl?.binding?.options,
                    value.imageUrl?.imageType
                  ).binding,
                imageType: value.imageUrl?.imageType,
              }
            : { ...getImageBinding(value.imageUrl, record) },
      };
      break;

    default:
      result = value;
  }

  return result;
};

const convertDate = (value: any) => {
  if (value) {
    const isDateTime = moment(
      value,
      DATE_FORMAT.DATE_LABEL_FORMAT,
      true
    ).isValid();

    if (isDateTime) return moment(value).format(DATE_FORMAT.DATE_LABEL_FORMAT);

    const isDate = moment(value, DATE_FORMAT.DATE_ONLY, true).isValid();

    if (isDate) return moment(value).format(DATE_FORMAT.DATE_ONLY);
  }

  return value;
};

export const bindingList = (
  record: any,
  attributes: any,
  itemIndex: number
) => {
  const currentId = attributes?.items?.id;

  const markerAddress =
    attributes.markerType &&
    convertDate(
      getTextBinding(
        get(
          attributes,
          attributes.markerType === 'simple'
            ? pathComponents.marker
            : pathComponents.markers
        ),
        record
      )
    );

  const lngValue = getTextBinding(
    get(attributes, pathComponents.pinGeometry.lng),
    record
  );

  const latValue = getTextBinding(
    get(attributes, pathComponents.pinGeometry.lat),
    record
  );
  const markerGeometry =
    isEmpty(lngValue) && isEmpty(latValue)
      ? null
      : {
          lng: convertDate(lngValue),
          lat: convertDate(latValue),
          _id: record?._id,
        };
  const title = get(attributes, pathComponents.title);

  const subTitle = get(attributes, pathComponents.subTitle);

  const subTitle2 = get(attributes, pathComponents.subTitle2);

  const image = get(attributes, pathComponents.image);

  const imageUrl = get(attributes, pathComponents.imageUrl);

  const button1 = get(attributes, pathComponents.button1);

  const tag = get(attributes, pathComponents.tag);

  const footer = get(attributes, pathComponents.footer);

  const avatarImage = get(attributes, pathComponents.avatarImage);

  const avatarImageUrl = get(attributes, pathComponents.avatarImageUrl);

  const leftSectionValue = get(attributes, pathComponents.leftSection);

  const leftSection = leftSectionValue
    ? getLeftSectionValue(leftSectionValue, record)
    : null;

  const isImage = image?.imageType === 'url' ? imageUrl : image;
  const isImageAvt =
    avatarImage?.imageType === 'url' ? avatarImageUrl : avatarImage;

  const imageBinding = getImageBinding(isImage, record);
  const imageBinidngAvt = getImageBinding(isImageAvt, record);

  return {
    _id: record._id,
    markerAddress,
    markerGeometry,
    title: convertDate(
      getTextBinding(title, record, undefined, itemIndex, currentId)
    ),
    subTitle: convertDate(
      getTextBinding(subTitle, record, undefined, itemIndex, currentId)
    ),
    subTitle2: convertDate(
      getTextBinding(subTitle2, record, undefined, itemIndex, currentId)
    ),
    image: imageBinding,
    ...(button1
      ? {
          button1: getTextBinding(
            button1,
            record,
            undefined,
            itemIndex,
            currentId
          ),
        }
      : null),
    ...(leftSection ? { leftSection } : null),
    agenda: {
      ...getAgendaFieldValue(record, attributes, itemIndex),
    },
    ...(attributes?.tag && {
      tag: getTextBinding(tag, record, undefined, itemIndex, currentId),
    }),
    ...(attributes?.footer && {
      footer: getTextBinding(footer, record, undefined, itemIndex, currentId),
    }),
    ...(attributes?.avatarImage && {
      avatarImage: imageBinidngAvt,
    }),
  };
};

const getAgendaFieldValue = (
  record: any,
  attributes: any,
  itemIndex: number
) => {
  return {
    id: +itemIndex + 1,
    eventTitle: getTextBinding(
      get(attributes, 'agenda.eventTitle'),
      record,
      undefined,
      itemIndex
    ),
    eventSubtitle: getTextBinding(
      get(attributes, 'agenda.eventSubtitle'),
      record,
      undefined,
      itemIndex
    ),
    eventStarttime: getTextBinding(
      get(attributes, 'eventStarttime'),
      record,
      undefined,
      itemIndex
    ),
    eventEndtime: getTextBinding(
      get(attributes, 'eventEndtime'),
      record,
      undefined,
      itemIndex
    ),
  };
};
