import ObjectRender from '@common/screens/RenderScreen/ObjectRender';
import { isEmpty, isNil, map } from 'lodash';
import React from 'react';
import { TouchableOpacity } from 'react-native';

const Item = ({ item, attrs, index }: any) => {
  const { children, rowMargin } = attrs;

  // add attributes to children
  let newChildren = children.map((child: any) => ({
    ...child,
    id: child.id + '__' + index,
    selectedItem: {
      itemId: item?._id,
      tableId: attrs.databaseUuid,
      itemIndex: index,
      externalId: item?.id,
      externalRecord: item,
    },
    groupActionId: attrs?.actions && Object.keys(attrs.actions)[0],
    record: item,
    isInCustomList: true,
    currentListId: attrs.id,
    parentListDatabaseUuid: attrs.parentListDatabaseUuid || attrs.databaseUuid,
    parentListItemId: attrs.parentListItemId || attrs.record?._id,
    ...(isNil(child.actions) || isEmpty(child.actions)
      ? { actions: attrs.actions }
      : {
          actions: {
            ...child?.actions,
            ...attrs?.actions,
          },
        }),
  }));

  let sectionGroup: Array<any> = [];
  if (newChildren.length > 1 && newChildren[0].type == 'section') {
    let el = newChildren[0];
    let sectionChildren: Array<any> = [];
    let lastBottomLeft = 0;
    for (let i = 0; i < newChildren.length; i++) {
      if (i == 0) {
        continue;
      } else {
        let o = newChildren[i];
        const currentBottomLeft = o.y + o.height;
        if (o.y >= el.y && currentBottomLeft <= el.y + el.height) {
          sectionChildren.push(o);
          if (currentBottomLeft > lastBottomLeft) {
            lastBottomLeft = currentBottomLeft;
          }
        } else {
          sectionGroup.push(o);
        }
      }
    }
    if (lastBottomLeft) el.paddingBottom = el.y + el.height - lastBottomLeft;
    el.children = sectionChildren;
    sectionGroup.push(el);
  } else {
    sectionGroup = newChildren;
  }

  return (
    <TouchableOpacity
      onPress={(e) => {
        if (!isEmpty(attrs?.actions)) {
          e.preventDefault();
          attrs.onPress &&
            attrs.onPress('', {
              itemId: item._id,
            });
        }
      }}
      style={{
        marginBottom: rowMargin,
      }}
    >
      <ObjectRender
        keyItem={index}
        arrComp={sectionGroup}
        isScreen={false}
        layout={{
          offsetTop: attrs?.y,
          offsetLeft: attrs?.x,
          parentWidth: attrs?.width,
        }}
      />
    </TouchableOpacity>
  );
};

export default Item;
