import { getMessage } from '@common/constants/locale';
import { getRecords, getTable } from '@common/utils/database';
import {
  addItemDataSource,
  setCreateRecordAddUpdate,
  setCurrentRecord,
  setReloadStatus,
} from '@common/redux/slice/database';
import { setError } from '@common/redux/slice/formInput';
import store from '@common/redux/store';
import { IRecord } from '@common/types/';
import { MetaData } from '@common/types/action';
import excuteCreateExternal from '@common/utils/handleActions/ActionItem/createExternal';
import { filter, get, isEmpty } from 'lodash';
import { ActionResponse } from '../excuteAction';
import { createRecordApi } from '../func/apiFunction';
import { getCollectionNameUuid, parsePayload } from '../func/func';

const excuteCreateObject: (
  metadata: MetaData,
  currentRecord?: any,
  locale?: string
) => Promise<ActionResponse> = async (metadata, currentRecord, locale) => {
  const { databaseUuid, record, autoValues, handleResetValue } = metadata;

  const state: any = store.getState();
  const auth = state.auth.profile;
  const dispatch = store.dispatch;

  const appUser = state?.appInfo?.app?.app_team?.map(
    (user: any) => user.user_id
  );
  const newPayload = parsePayload({
    databaseUuid,
    record: {
      ...autoValues,
      ...record,
    },
  });

  const table = getTable(databaseUuid);

  if (table && table.isThirdParty) {
    return excuteCreateExternal(table, newPayload, dispatch, state);
  }

  try {
    const recordArray: Array<IRecord> = getRecords(databaseUuid);
    const validEmail = isEmpty(
      filter(recordArray, ['record.email', record.email])
    );

    if (!validEmail) {
      dispatch(
        setError({
          message: 'Email is ready exist !',
          isError: true,
        })
      );

      return {
        status: 'FAILED',
        message: 'Email is ready exist !',
      };
    }

    //breack when create duplicate email
    const recordCreated = await createRecordApi(
      databaseUuid,
      {
        ...newPayload,
        creator: auth?.email,
      },
      appUser
    );

    if (recordCreated) {
      dispatch(setReloadStatus(true));

      // if (
      //   !metadata?.mappingField ||
      //   !Object.keys(metadata?.mappingField).length
      // ) {

      const state: any = store.getState();

      handleResetValue();

      dispatch(setCurrentRecord(recordCreated));

      dispatch(
        setCreateRecordAddUpdate({
          databaseUuid,
          record: recordCreated,
        })
      );
      dispatch(addItemDataSource(recordCreated));
    }

    const message =
      locale === 'ja'
        ? `データが登録されました`
        : `Record created in ${getCollectionNameUuid(databaseUuid)}`;

    return {
      status: 'SUCCEED',
      message,
      target: currentRecord,
      current: recordCreated,
    };
  } catch (err: any) {
    const getError = get(err, 'response.data.message');

    dispatch(
      setError({
        message: getMessage({
          locale,
          id: getError,
        }),
        isError: true,
      })
    );

    return {
      status: 'FAILED',
      message: err?.response?.data?.message || err?.message,
    };
  }
};

export default excuteCreateObject;
