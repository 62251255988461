import { useCallback, useEffect, useState } from 'react';
import { get, includes, isEmpty, omit } from 'lodash';
import sign from 'jwt-encode';
import qs from 'query-string';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import api from '@common/configs/api';
import { setActionType } from '@common/redux/slice/customAction';
import history from '@common/routes/history';
import { useLocation } from '@common/routes/hooks';
import { getTextBinding } from '@common/utils/handleBinding';
import { getDataEncrypt } from '@common/utils/encryptData';

const ODER: { [key: string]: number } = {
  asc: -1,
  desc: 1,
};

const COMPARATORS: { [key: string]: string } = {
  '==': 'eq',
  '!=': 'ne',
  '>': 'gt',
  '<': 'lt',
  '>=': 'gte',
  '<=': 'lte',
  contains: 'contains',
  notContains: 'not_contains',
  between: 'between',
  listContains: 'listContains',
  listNotContains: 'listNotContains',
  true: 'eq',
  false: 'eq',
};

const flattenObj: any = (data: any) => {
  let result = [];

  while (data) {
    result.push(omit(data, 'source'));
    data = data.source;
  }

  return result
    .reverse()
    .filter((item: any) => item.fieldId)
    .map((item: any) => item.fieldId);
};

const convertDate = (value: string) => {
  if (value) {
    const isDate = moment(value, 'YYYY/MM/DD', true).isValid();
    const isDateTime =
      moment(value, 'YYYY/MM/DD HH:mm:ss', true).isValid() ||
      moment(value, 'YYYY/MM/DD HH:mm', true).isValid();

    if (isDate) return moment(value).format('YYYY-MM-DD');

    if (isDateTime) return moment(value).toISOString();

    return value;
  }

  return value;
};

export const handlePayload = ({ source = [] }) => {
  return source.map((item: any[]) => {
    item = item.filter((o: any) => o?.fieldId);

    return item.map((obj: any) => ({
      comparation: COMPARATORS[obj.comparator],
      value: includes(['true', 'false'], obj.comparator)
        ? obj.comparator
        : convertDate(
            getTextBinding({ source: obj.comparison, type: 'binding' }, {})
          ),
      fieldId: obj.fieldId?.source ? flattenObj(obj?.fieldId) : obj.fieldId,
      ...(obj.comparison2 && {
        value2: getTextBinding(
          { source: obj.comparison2, type: 'binding' },
          {}
        ),
      }),
    }));
  });
};

export const fetchRecords = (props: {
  obj: any;
  databaseId: string;
  handlePress: any;
}) => {
  const [response, setResponse] = useState<any>({
    data: [],
    total: 0,
    loading: false,
    page: 1,
    error: '',
  });

  const { search } = useLocation();
  const dispatch = useDispatch();
  const params = qs.parse(search);

  const filter = get(props, 'obj.attributes.items.source.options.filter', []);
  const sort = get(props, 'obj.attributes.items.source.sort', null);
  const maximum = get(props, 'obj.attributes.items.source.options.limit', null);

  const payload = handlePayload({
    source: filter,
  }).filter((item: any) => !isEmpty(item));

  const columnFilter = sign(payload, '123');
  const appId = history.location.pathname.split('/')[2];

  const handleData = (data: any) => {
    if (maximum === 0) return [];

    return maximum ? data.slice(0, maximum) : data;
  };

  const setLoadMore = useCallback(
    async (page: number) => {
      dispatch(
        setActionType({
          actionType: '',
          databaseId: '',
        })
      );

      setResponse({
        ...response,
        ...{ loading: true, error: '' },
      });

      try {
        const res = await api({
          method: 'get',
          url: `v2/database/${props.databaseId}/records/`,
          params: {
            column_filter: columnFilter,
            appId,
            limit: 10,
            page: page,
            ...(sort && {
              orderBy: sort.fieldId,
              sort: ODER[sort.direction],
            }),
          },
        }).then((responseApi) => ({
          ...responseApi,
          data: getDataEncrypt(responseApi?.data),
        }));

        setResponse({
          data: handleData(
            page !== 1 ? [...response.data, ...res.data.data] : res.data.data
          ),
          total: res.data.total,
          page: res.data.page,
          loading: false,
          error: '',
        });
      } catch (error) {
        setResponse({
          data: [],
          total: 0,
          page: 1,
          loading: false,
          error: get(error, 'response.data.message') || `Can't get data`,
        });
        console.log('error', error);
      }
    },
    [
      response,
      setResponse,
      columnFilter,
      sort,
      maximum,
      appId,
      props.databaseId,
    ]
  );

  useEffect(() => {
    setLoadMore(1);
  }, [params?.target, params?.targetModal, props.obj, columnFilter]);

  return { response, setLoadMore };
};
