import { Attributes } from '@nocode/types/avatarList.type';
import createStyles from './style';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import isNil from 'lodash/isNil';
import queryString from 'query-string';
import { defaultURL } from '../../../utils/common.constant';
import {
  checkFont,
  checkFontWeight,
  getTextDecorationLine,
  parseText,
} from '../../func';

export type ReceivedProps = {
  attributes: Attributes;
  index: number;
  item: any;
  onPress?: (id: string, item: Record<string, any>) => void;
  isWeb?: boolean;
  records: Record<string, any>;
};

const useAvatar = (props: ReceivedProps) => {
  const styles = createStyles();

  const {
    attributes,
    item,
    onPress,
    groupActionId,
    records,
  }: Record<string, any> = props;
  const { cardCover, title, subTitle } = attributes;

  const { textDecorationUnderline = false, textDecorationLineThrough = false } =
    title || {};

  const search = !isEmpty(window)
    ? queryString.parse(window?.location?.search)
    : {};
  const target = search?.target;

  const imageType = cardCover?.image?.imageType;
  const placeholderImageUrl =
    attributes?.cardCover?.image?.binding?.options?.placeholderImage;

  const placeholderImageEnabled =
    !!attributes?.cardCover?.image?.binding?.options?.placeholderImageEnabled;

  const placeholderImage =
    placeholderImageEnabled && imageType !== 'uploaded'
      ? placeholderImageUrl
      : null;

  const imageCanvas =
    typeof cardCover?.imageUrl === 'string'
      ? cardCover?.imageUrl || placeholderImage
      : Array.isArray(cardCover?.imageUrl)
      ? cardCover?.imageUrl[0] || placeholderImage
      : placeholderImage;

  const imageUrl = (item: any) => {
    const binding = get(item, 'image.binding');

    const isObj = filter(
      cardCover?.imageUrl,
      (item) => typeof item === 'object'
    );

    if (imageType === 'internal') {
      return binding || placeholderImage || defaultURL;
    }

    if (imageType === 'url' && !isEmpty(isObj)) {
      if (binding) {
        return binding || placeholderImage || defaultURL;
      } else {
        return item?.image || placeholderImage || defaultURL;
      }
    }

    return imageCanvas || defaultURL;
  };

  const getTextStyles = (styles: any) => {
    const {
      textDecorationUnderline,
      textDecorationLineThrough,
      fontFamily,
      fontWeightBold,
      fontSize,
      color,
      textAlignment,
      fontStyleItalic,
    } = styles || {};
    const textStyle = getTextDecorationLine({
      textDecoration: textDecorationUnderline,
      linethrough: textDecorationLineThrough,
    });

    return {
      ...(!fontWeightBold &&
        !fontStyleItalic && {
          fontFamily: checkFont(fontFamily, fontWeightBold, target),
        }),
      fontSize: fontSize,
      color: color,
      textAlign: textAlignment,
      textDecorationLine: textStyle,
      fontStyle: (fontStyleItalic ? 'italic' : 'normal') as 'normal' | 'italic',
      fontWeight: checkFontWeight(fontWeightBold, fontFamily),
    };
  };

  const urlImage = imageUrl(item);
  const titleText = parseText(item.title);
  const subTitleText = parseText(item?.subTitle);

  const handlePress = (id: string, item: Record<string, any>) => {
    if (id === 'onPress') {
      if (isNil(attributes[id]) || isEmpty(attributes[id])) {
        onPress &&
          onPress(undefined, {
            itemId: item.itemId,
            groupActionId,
            indexRecord: item.indexRcord,
            externalId: records[item.indexRcord]?.id,
          });
      } else {
        onPress &&
          onPress(id, {
            itemId: item.itemId,
            indexRecord: item.indexRcord,
            externalId: records[item.indexRcord]?.id,
            externalRecord: records[item.indexRcord],
          });
      }
    } else {
      const sectionOnpress = get(attributes, `${id}.onPress`, {});

      if (isEmpty(sectionOnpress)) {
        onPress &&
          onPress('onPress', {
            itemId: item.itemId,
            groupActionId,
            indexRecord: item.indexRcord,
            externalId: records[item.indexRcord]?.id,
            externalRecord: records[item.indexRcord],
          });
      } else {
        onPress &&
          onPress(id, {
            itemId: item.itemId,
            indexRecord: item.indexRcord,
            externalId: records[item.indexRcord]?.id,
            externalRecord: records[item.indexRcord],
          });
      }
    }
  };

  return {
    ...props,
    getTextStyles,
    styles,
    target,
    urlImage,
    titleText,
    subTitleText,
    placeholderImage,
    handlePress,
  };
};

export type Props = ReturnType<typeof useAvatar>;

export default useAvatar;
