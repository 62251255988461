import { setCurrentLocation } from '@common/redux/slice/database';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const useMapLocation = () => {
  const dispatch = useDispatch();
  const [initializedMap, setInitializedMap] = useState(false);

  const getPosition = function () {
    return new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  };

  useEffect(() => {
    getPosition()
      .then((position: any) => {
        if (position) {
          if (position.coords) {
            dispatch(
              setCurrentLocation({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              })
            );
          }
        }
      })
      .catch((err) => {
        console.log('err', err);
      })
      .finally(() => {
        setInitializedMap(true);
      });
  }, []);

  return { initializedMap };
};

export default useMapLocation;
