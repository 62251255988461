import { IChipList } from '@nocode/types';
import { filter, get, isEmpty, isNil } from 'lodash';
import React from 'react';
import { Image, View } from 'react-native';
import { Chip, Paragraph } from 'react-native-paper';
import { defaultURL } from '../../../utils/common.constant';
import createStyles from '../style';

type Props = {
  attrs: IChipList;
  index: number;
  item: any;
  onPress?: (id: string | undefined, options: Record<string, any>) => void;
};

const ChipItem = ({ attrs, index, item, onPress }: Props) => {
  const styles = createStyles(attrs);

  const {
    closeIcon = { enabled: false },
    title = { enabled: false },
    cardCover,
  } = attrs.attributes;

  const isCanvas =
    window?.location?.href &&
    window.location.href.split('/').includes('canvas');

  const records = attrs.records;

  const imageType = get(cardCover, 'image.imageType');

  const placeholderImageUrl = get(
    attrs,
    'attributes.cardCover.image.binding.options.placeholderImage',
    null
  );

  const placeholderImageEnabled = get(
    attrs,
    'attributes.cardCover.image.binding.options.placeholderImageEnabled',
    false
  );

  const isIconAction = get(attrs, 'attributes.closeIcon.onPress', false);

  const placeholderImage =
    placeholderImageEnabled && imageType !== 'uploaded'
      ? placeholderImageUrl
      : null;

  const imageCanvas =
    typeof cardCover?.imageUrl === 'string'
      ? cardCover?.imageUrl || placeholderImage
      : Array.isArray(cardCover?.imageUrl)
      ? cardCover?.imageUrl[0] || placeholderImage
      : placeholderImage;

  const imageUrl = (item: any) => {
    const binding = get(item, 'image.binding');

    const isObj = filter(
      cardCover?.imageUrl,
      (item) => typeof item === 'object'
    );

    if (imageType === 'internal') {
      return binding || placeholderImage;
    }

    if (imageType === 'url' && !isEmpty(isObj)) {
      if (binding) {
        return binding || placeholderImage;
      } else {
        return item?.image || placeholderImage;
      }
    }

    return imageCanvas;
  };

  const handlePress = (id: string, item: Record<string, any>) => {
    const { attributes, groupActionId }: Record<string, any> = attrs;

    if (id === 'onPress') {
      if (isNil(attributes[id]) || isEmpty(attributes[id])) {
        onPress &&
          onPress(undefined, {
            itemId: item.itemId,
            groupActionId,
            indexRecord: item.indexRcord,
            externalId: records[item.indexRcord]?.id,
          });
      } else {
        onPress &&
          onPress(id, {
            itemId: item.itemId,
            indexRecord: item.indexRcord,
            externalId: records[item.indexRcord]?.id,
          });
      }
    } else {
      const sectionOnpress = get(attributes, `${id}.onPress`, {});
      if (isEmpty(sectionOnpress)) {
        onPress &&
          !!isIconAction &&
          onPress('onPress', {
            itemId: item.itemId,
            groupActionId,
            indexRecord: item.indexRcord,
            externalId: records[item.indexRcord]?.id,
          });
      } else {
        onPress &&
          onPress(id, {
            itemId: item.itemId,
            indexRecord: item.indexRcord,
            externalId: records[item.indexRcord]?.id,
          });
      }
    }
  };

  return (
    <Chip
      {...(closeIcon.enabled && closeIcon.icon !== ''
        ? {
            closeIcon: closeIcon.icon,
            onClose: () => {
              handlePress('closeIcon', {
                itemId: item._id,
                indexRcord: index,
              });
            },
            selectedColor: attrs.attributes.iconColor,
          }
        : {})}
      {...(attrs.attributes?.onPress && {
        onPress: () => {
          handlePress('onPress', {
            itemId: item._id,
            indexRcord: index,
          });
        },
      })}
      style={styles.chipContent}
      textStyle={styles.text}
      avatar={
        <>
          {!placeholderImage && !imageUrl(item) && !isCanvas ? null : (
            <View style={styles.avatar}>
              <Image
                style={{
                  height: '100%',
                  width: '100%',
                  borderRadius: cardCover.borderRadius,
                }}
                defaultSource={{ uri: placeholderImage }}
                source={{
                  uri: isCanvas ? imageUrl(item) || defaultURL : imageUrl(item),
                }}
              />
            </View>
          )}
        </>
      }
    >
      {title.enabled && <Paragraph style={styles.text}>{item.title}</Paragraph>}
    </Chip>
  );
};

export default ChipItem;
