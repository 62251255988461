import { useSelector } from 'react-redux';
import { pageInfoSelector } from '@common/redux/selectors/page';
import { View, Text, Platform, StyleSheet, Image } from 'react-native';
import React, { useCallback, useEffect } from 'react';
import { getAllPages } from '@common/hooks/page';
import { useParams, useLocation } from '@common/routes/hooks';
import RenderScreen from './RenderScreen';
import history from '@common/routes/history';
import useAppDetail from '@common/hooks/app';
import { initializeAdmob } from '@common/hooks/admob';
import { BackHandler } from 'react-native';
import { pastLocationSelector } from '@common/redux/selectors/locationHistory';
import RNLine from '@nocode/components/LineLogin/lineModal';
import getCusomActions from '@common/hooks/customAction';
import { dimensionSelector } from '@common/redux/selectors/page';
import useDebug from '@common/hooks/useDebug';
import Loading from '@common/components/Loading';
import useMapLocation from '@common/hooks/useLocation';
export default function Render() {
  const pastLocations = useSelector(pastLocationSelector);
  const dimension = useSelector(dimensionSelector);

  const params = useParams();

  if (!params?.appId) {
    return (
      <View>
        <Text>Cannot found page</Text>
      </View>
    );
  }

  const { search } = useLocation();
  let debug = useDebug();

  const {
    data: appInfo,
    collections,
    isLoading: loadingDatabase,
  } = useAppDetail({
    appId: params.appId,
    debug: debug,
  });

  const handleDeviceBack = useCallback(() => {
    if (pastLocations.length <= 1) BackHandler.exitApp();
    history.goBack();
    return true;
  }, [pastLocations]);

  const { isHaveDevelopPlugin, isDevelopPluginLoading } =
    useSelector(pageInfoSelector);
  const idPluginLoading = isHaveDevelopPlugin && isDevelopPluginLoading;

  const { data: customActions } = getCusomActions({
    appId: params.appId,
    debug,
  });

  useEffect(() => {
    if (Platform.OS === 'web' && appInfo) {
      const isHaveScriptGA4 = document
        .getElementById('scripFuncId')
        ?.getElementsByTagName('script');
      if (!isHaveScriptGA4) {
        const { ga4 } = JSON.parse(appInfo?.metadata || '{}');
        const scriptImport = document.createElement('script');
        const scriptFunc = document.createElement('script');
        const textnode = document.createTextNode(`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${ga4?.id}',{cookie_flags: 'secure;samesite=none'});`);

        scriptFunc.appendChild(textnode);
        scriptImport.async = true;
        scriptImport.src = `https://www.googletagmanager.com/gtag/js?id=${ga4?.id}`;
        scriptImport.setAttribute('id', 'scripImportId');
        scriptFunc.setAttribute('id', 'scripFuncId');
        document.head.appendChild(scriptImport);
        document.head.appendChild(scriptFunc);
      }
    }
  }, [appInfo]);

  useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', handleDeviceBack);

    return () =>
      BackHandler.removeEventListener('hardwareBackPress', handleDeviceBack);
  }, [pastLocations]);

  initializeAdmob({ appInfo });

  const { initializedMap } = useMapLocation();

  const { data, error, isLoading } = getAllPages(params.appId, debug);

  const isLoadingApp =
    isLoading || loadingDatabase || !initializedMap; /*|| idPluginLoading*/

  if (isLoadingApp) return <Loading />;

  const handleRenderScreen = () => {
    if (data && data.length > 0 && collections && appInfo && customActions) {
      return <RenderScreen />;
    } else {
      return (
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            width: dimension.width,
            height: dimension.height,
            backgroundColor: 'white',
          }}
        >
          <Image
            source={require('@common/assets/images/splashScreen.png')}
            style={{ width: 200, height: 200 }}
          />
        </View>
      );
    }
  };

  return (
    <React.Fragment>
      <RNLine.component ref={RNLine.init} />
      {handleRenderScreen()}
      {error && (
        <View style={styles.errorWrapper}>
          <Text style={styles.errorText}>{error}</Text>
        </View>
      )}
    </React.Fragment>
  );
}

const styles = StyleSheet.create({
  errorWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorText: {
    color: 'red',
  },
});
