import * as React from 'react';
import { ICardList } from '@nocode/types';
import { TouchableOpacity, Platform } from 'react-native';
import { Button, Card, Paragraph, Title, Subheading } from 'react-native-paper';
import createStyles from '../style';
import LeftSection from '../../Common/SectionLeftRight';
import { get, filter, isEmpty, isNil } from 'lodash';
import { checkFont } from '../../func';
import ContentLoader, { Rect } from 'react-content-loader/native';
import { defaultURL } from '../../../utils/common.constant';

type Props = {
  attrs: ICardList;
  index: number;
  item: any;
  onPress?: (id: string | undefined, options: Record<string, any>) => void;
  isWeb?: boolean;
};

const CardItem = ({ attrs, index, item, onPress, isWeb }: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const styles = createStyles(attrs, index + 1, isLoading, Platform, isWeb);

  const {
    title = { enabled: false },
    subTitle = { enabled: false },
    subTitle2 = { enabled: false },
    cardCover,
    button1,
    icon1,
    icon2,
    opacity,
    backgroundColor,
    borderColor,
    borderWidth,
    borderRadius,
    borderStyle,
    fontFamily,
    fontSize,
    color,
    // iconColor,
  } = attrs.attributes;

  const records = attrs.records;

  const itemMargin = attrs.attributes.gap || 2;
  const columnCount = get(attrs.attributes, 'columnCount', 2);
  const cardItemOffset = (attrs.width * 100) / columnCount / 100;

  const imageType = get(cardCover, 'image.imageType');

  const placeholderImageUrl = get(
    attrs,
    'attributes.cardCover.image.binding.options.placeholderImage',
    null
  );

  const placeholderImageEnabled = get(
    attrs,
    'attributes.cardCover.image.binding.options.placeholderImageEnabled',
    false
  );

  const placeholderImage =
    placeholderImageEnabled && imageType !== 'uploaded'
      ? placeholderImageUrl
      : null;

  const imageCanvas =
    typeof cardCover?.imageUrl === 'string'
      ? cardCover?.imageUrl || placeholderImage
      : Array.isArray(cardCover?.imageUrl)
      ? cardCover?.imageUrl[0] || placeholderImage
      : placeholderImage;

  const imageUrl = (item: any) => {
    const binding = get(item, 'image.binding');

    const isObj = filter(
      cardCover?.imageUrl,
      (item) => typeof item === 'object'
    );

    if (imageType === 'internal') {
      return binding || placeholderImage || defaultURL;
    }

    if (imageType === 'url' && !isEmpty(isObj)) {
      if (binding) {
        return binding || placeholderImage || defaultURL;
      } else {
        return item?.image || placeholderImage || defaultURL;
      }
    }

    return imageCanvas;
  };

  const handlePress = (id: string, item: Record<string, any>) => {
    const { attributes, groupActionId }: Record<string, any> = attrs;

    if (id === 'onPress') {
      if (isNil(attributes[id]) || isEmpty(attributes[id])) {
        onPress &&
          onPress(undefined, {
            itemId: item.itemId,
            groupActionId,
            indexRecord: item.indexRcord,
            externalId: records[item.indexRcord]?.id,
          });
      } else {
        onPress &&
          onPress(id, {
            itemId: item.itemId,
            indexRecord: item.indexRcord,
            externalId: records[item.indexRcord]?.id,
            externalRecord: records[item.indexRcord],
          });
      }
    } else {
      const sectionOnpress = get(attributes, `${id}.onPress`, {});
      if (isEmpty(sectionOnpress)) {
        onPress &&
          onPress('onPress', {
            itemId: item.itemId,
            groupActionId,
            indexRecord: item.indexRcord,
            externalId: records[item.indexRcord]?.id,
            externalRecord: records[item.indexRcord],
          });
      } else {
        onPress &&
          onPress(id, {
            itemId: item.itemId,
            indexRecord: item.indexRcord,
            externalId: records[item.indexRcord]?.id,
            externalRecord: records[item.indexRcord],
          });
      }
    }
  };

  return (
    <Card
      onPress={() => {
        handlePress('onPress', {
          itemId: item._id,
          indexRcord: index,
        });
      }}
      style={[
        {
          opacity,
          backgroundColor,
          borderColor,
          borderWidth,
          borderRadius,
          borderStyle: borderStyle !== 'none' ? borderStyle : undefined,
        },
        styles.cardWrapper,
      ]}
    >
      {isLoading ? null : Platform.OS === 'ios' || Platform.OS === 'web' ? (
        <ContentLoader
          speed={3}
          // viewBox="0 0 200 110"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          // width={195 * (attrs?.originX || 1)}
          height={115}
          style={{
            borderRadius: +attrs.attributes?.cardCover?.borderRadius || 0,
          }}
        >
          <Rect
            x="0"
            y="0"
            rx="0"
            ry="0"
            width={cardItemOffset - +itemMargin * 2}
            height={115}
          />
        </ContentLoader>
      ) : null}
      {/* <LazyLoadComponent> */}
      <Card.Cover
        source={{ uri: imageUrl(item) }}
        style={styles.cardCover}
        onLoad={() => {
          setIsLoading(true);
        }}
      />
      {/* </LazyLoadComponent> */}
      <Card.Content style={styles.cardContent}>
        {title.enabled && (
          <Title
            style={{
              // fix font family in the future__________________
              fontFamily: checkFont(fontFamily),
              fontSize,
              color,
            }}
            numberOfLines={title && title.titleLineNum > 1 ? 500 : 1}
          >
            {item.title}
          </Title>
        )}
        {subTitle?.enabled && (
          <Subheading
            style={{
              fontFamily: checkFont(fontFamily),
              fontSize,
              color,
            }}
            numberOfLines={subTitle && subTitle.subtitleLineNum > 1 ? 500 : 1}
          >
            {item.subTitle}
          </Subheading>
        )}
        {subTitle2?.enabled && (
          <Paragraph
            style={{
              fontFamily: checkFont(fontFamily),
              fontSize,
              color,
            }}
            numberOfLines={
              subTitle2 && subTitle2.subtitle2LineNum > 1 ? 500 : 1
            }
          >
            {item.subTitle2}
          </Paragraph>
        )}
      </Card.Content>
      {(button1?.enabled || icon1?.enabled || icon2?.enabled) && (
        <Card.Actions style={styles.cardAction}>
          {button1.enabled && (
            <Button
              labelStyle={styles.buttonLabel}
              style={styles.actionsButton1}
              mode="contained"
              onPress={() => {
                handlePress('button1', {
                  itemId: item._id,
                  indexRcord: index,
                });
              }}
            >
              {item.button1}
            </Button>
          )}
          {icon1?.enabled && (
            <TouchableOpacity
              onPress={() => {
                handlePress('icon1', {
                  itemId: item._id,
                  indexRcord: index,
                });
              }}
            >
              <LeftSection values={icon1} />
            </TouchableOpacity>
          )}
          {icon2?.enabled && (
            <TouchableOpacity
              onPress={() => {
                handlePress('icon2', {
                  itemId: item._id,
                  indexRcord: index,
                });
              }}
            >
              <LeftSection values={icon2} />
            </TouchableOpacity>
          )}
        </Card.Actions>
      )}
    </Card>
  );
};

export default CardItem;
