import { pick } from 'lodash';
import { Platform, StyleSheet } from 'react-native';
import { getShadow } from '../func';

const createStyles = (attributes: any) => {
  return StyleSheet.create({
    container: {
      ...pick(attributes, ['marginTop', 'marginLeft']),
      // ...(attributes.shadow.size > 0 ? getShadow(attributes) : {}),
      ...(Platform.OS !== 'android' && getShadow(attributes)),
      ...pick(attributes, [
        'opacity',
        'width',
        'borderRadius',
        'backgroundColor',
        'zIndex',
      ]),
      ...(attributes?.isInCustomList
        ? { paddingBottom: attributes.paddingBottom }
        : {}),
      minHeight: attributes.height,
      // alignItems: 'center',
      // justifyContent: 'center',

      ...(Platform.OS === 'android' && attributes.borderRadius === 0
        ? { borderRadius: 1 }
        : { borderRadius: attributes.borderRadius * attributes.originX }),

      ...(Platform.OS === 'web'
        ? {
            ...(attributes.borderStyle !== 'none' && {
              borderStyle: attributes.borderStyle,
              borderWidth: attributes.borderWidth,
              borderColor: attributes.borderColor,
            }),
          }
        : {
            ...(attributes.borderStyle !== 'none'
              ? pick(attributes, ['borderWidth', 'borderColor'])
              : {}),
            borderStyle:
              attributes.borderStyle === 'none'
                ? 'solid'
                : attributes.borderStyle,
          }),
    },
  });
};

export default createStyles;
