import { Platform } from 'react-native';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import queryString from 'query-string';

import { IAvatarList } from '@nocode/types';
import createStyles from './style';

const useAvatarList = (attrs: IAvatarList) => {
  const {
    attributes: { opacity, backgroundColor, title, subTitle },
    dataBinding,
    onPress,
    records,
    isWeb,
  } = attrs;

  const handlePress = (id: string, item: Record<string, any>) => {
    const { attributes, groupActionId, databaseUuid }: Record<string, any> =
      attrs;

    if (id === 'onPress') {
      if (isNil(attributes[id]) || isEmpty(attributes[id])) {
        onPress &&
          onPress(undefined, {
            itemId: item.itemId,
            groupActionId,
            indexRecord: item.indexRcord,
            externalId: records[item.indexRcord]?.id,
          });
      } else {
        onPress &&
          onPress(id, {
            itemId: item.itemId,
            indexRecord: item.indexRcord,
            externalId: records[item.indexRcord]?.id,
          });
      }
    } else {
      const sectionOnpress = get(attributes, `${id}.onPress`, {});
      if (isEmpty(sectionOnpress)) {
        onPress &&
          onPress('onPress', {
            itemId: item.itemId,
            groupActionId,
            indexRecord: item.indexRcord,
            externalId: records[item.indexRcord]?.id,
          });
      } else {
        onPress &&
          onPress(id, {
            itemId: item.itemId,
            indexRecord: item.indexRcord,
            externalId: records[item.indexRcord]?.id,
          });
      }
    }
  };

  const search = !isEmpty(window)
    ? queryString.parse(window?.location?.search)
    : {};
  const target = search?.target;

  const getInitValues = () => {
    let len = 5;

    if (Platform.OS !== 'web') {
      // App
      return dataBinding;
    } else {
      // web
      if (target) {
        // preview
        return dataBinding;
      } else {
        // client
        return Array.from(Array(len), () =>
          dataBinding
            ? dataBinding[0]
            : {
                title: title.text,
                subTitle: subTitle?.text,
              }
        );
      }
    }
  };

  const listData = getInitValues();

  const styles = createStyles();

  const tableId = get(attrs, 'attributes.items.source.tableId');

  const isCanvas = Platform.OS === 'web' && !target;

  return {
    ...attrs,
    listData,
    target,
    isWeb,
    styles,
    tableId,
    handlePress,
    backgroundColor,
    opacity,
    isCanvas,
  };
};

export type Props = ReturnType<typeof useAvatarList>;

export default useAvatarList;
