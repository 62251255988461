import { ISimpleList } from '@nocode/types';
import { findLastIndex, get, isEmpty, isNil } from 'lodash';
import queryString from 'query-string';
import React, { useState, useMemo, memo } from 'react';
import { FlatList, Platform, Text, TouchableOpacity, View } from 'react-native';
import { List } from 'react-native-paper';
import LeftSection from '../Common/SectionLeftRight';
import EmptyList from '../EmptyList';
import { checkFont } from '../func';
import SkeletonLoading from '../SkeletonLoading/SimpleListPlaceholder';
import createStyles from './style';

const Conversions: Record<string, string> = {
  '&nbsp;': '\u00A0',
  '&gt;': '>',
  '&lt;': '<',
  '&amp;': '&',
} as const;

const SimpleList = (attrs: ISimpleList) => {
  const {
    attributes: {
      listHeader,
      subTitle,
      subTitle2,
      leftSection,
      rightSection,
      dividerType,
      scrollActive,
      // dividerColor,
      iconColor,
      opacity,
      backgroundColor,
      borderColor,
      borderWidth,
      borderRadius,
      borderStyle,
      fontFamily,
      fontSize,
      color,
      title,
      rowCount,
    },
    dataBinding,
    marginTop,
    isPreview,
    onPress,
    records,
    auth,
    isWeb,
    externalCollection,
    onLoadMoreFilter,
    totalPage,
    initializeList,
  } = attrs;
  const handlePress = (id: string, item: Record<string, any>) => {
    const { attributes, groupActionId, databaseUuid }: Record<string, any> =
      attrs;

    if (id === 'onPress') {
      if (isNil(attributes[id]) || isEmpty(attributes[id])) {
        onPress &&
          onPress(undefined, {
            itemId: item.itemId,
            groupActionId,
            indexRecord: item.indexRecord,
            externalId: records[item.indexRecord]?.id,
          });
      } else {
        onPress &&
          onPress(id, {
            itemId: item.itemId,
            indexRecord: item.indexRecord,
            externalId: records[item.indexRecord]?.id,
            externalRecord: records[item.indexRecord],
          });
      }
    } else {
      const sectionOnpress = get(attributes, `${id}.onPress`, {});
      if (isEmpty(sectionOnpress)) {
        onPress &&
          onPress('onPress', {
            itemId: item.itemId,
            groupActionId,
            indexRecord: item.indexRecord,
            externalId: records[item.indexRecord]?.id,
            externalRecord: records[item.indexRecord],
          });
      } else {
        onPress &&
          onPress(id, {
            itemId: item.itemId,
            indexRecord: item.indexRecord,
            externalId: records[item.indexRecord]?.id,
            externalRecord: records[item.indexRecord],
          });
      }
    }
  };

  if (initializeList) {
    return <SkeletonLoading attrs={attrs} initialize={true} />;
  }

  const [page, setPage] = useState(1);
  const isLoadMore = totalPage > page;

  const getInitValues = () => {
    let len = rowCount || 3;

    if (Platform.OS !== 'web') {
      // App
      return dataBinding;
    } else {
      // web
      const search = queryString.parse(window?.location?.search);
      const target = search?.target;

      if (target) {
        // preview
        return dataBinding;
      } else {
        // client
        return Array.from(Array(len), () =>
          dataBinding
            ? dataBinding[0]
            : {
                title: title.text,
                subTitle: subTitle.text,
                subTitle2: subTitle2.text,
              }
        );
      }
    }
  };

  const listData = useMemo(() => getInitValues(), [dataBinding]);

  const parseText = (val: string | Record<string, any>) =>
    typeof val === 'string'
      ? val.replace(/&(nbsp|amp|quot|lt|gt);/g, (i: string) => Conversions[i])
      : Array.isArray(val)
      ? val[0].replace(
          /&(nbsp|amp|quot|lt|gt);/g,
          (i: string) => Conversions[i]
        )
      : 'Error';

  const styles = createStyles();

  const isFixedHeight: any = scrollActive
    ? { contentContainerStyle: { flexGrow: 0, height: 'auto', margin: 0 } }
    : {};

  const tableId = get(attrs, 'attributes.items.source.tableId');

  const search = !isEmpty(window)
    ? queryString.parse(window?.location?.search)
    : {};

  const target = search?.target;

  const emptyList = (target && !tableId) || (!tableId && Platform.OS !== 'web');

  if (emptyList)
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        <EmptyList attributes={attrs} />
      </View>
    );

  const ListFooterComponent = () => {
    return (
      <TouchableOpacity
        onPress={() => {
          if (isLoadMore) {
            setPage(page + 1);
            onLoadMoreFilter(page + 1);
          }
        }}
      >
        {attrs.loadingFilter ? (
          <SkeletonLoading attrs={attrs} initialize={false} />
        ) : (
          <Text
            style={{
              fontSize: 14,
              textDecorationLine: 'underline',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Load more
          </Text>
        )}
      </TouchableOpacity>
    );
  };

  const renderItemList = ({ item, index }: any) => (
    <List.Item
      key={index}
      title={parseText(item.title)}
      titleNumberOfLines={title && title.titleLineNum > 1 ? 500 : 1}
      onPress={() => {
        handlePress('onPress', {
          itemId: item._id,
          indexRcord: index,
        });
      }}
      description={() => (
        <View>
          {subTitle.enabled && (
            <Text
              numberOfLines={subTitle && subTitle.subtitleLineNum > 1 ? 500 : 1}
              style={{
                fontSize,
                fontFamily: checkFont(fontFamily),
                color,
              }}
            >
              {get(subTitle, 'enabled', false) ? parseText(item.subTitle) : ''}
            </Text>
          )}
          {subTitle2.enabled && (
            <Text
              style={{
                fontSize: 11,
                fontFamily: checkFont(fontFamily),
                color,
              }}
              numberOfLines={
                subTitle2 && subTitle2.subtitle2LineNum > 1 ? 500 : 1
              }
            >
              {subTitle2.enabled ? parseText(item.subTitle2) : ''}
            </Text>
          )}
        </View>
      )}
      left={() =>
        leftSection?.enabled ? (
          <LeftSection
            values={{
              ...(item?.leftSection || leftSection || {}),
              iconColor,
            }}
            records={records}
            auth={auth}
            id={item._id}
            externalCollection={externalCollection}
            index={index}
          />
        ) : null
      }
      right={() =>
        rightSection?.enabled ? (
          <TouchableOpacity
            onPress={() => {
              handlePress('rightSection', {
                itemId: item._id,
                indexRcord: index,
              });
            }}
          >
            <LeftSection
              values={{ ...(rightSection || {}), iconColor }}
              records={records}
              id={item._id}
              auth={auth}
              externalCollection={externalCollection}
              index={index}
            />
          </TouchableOpacity>
        ) : null
      }
      style={{
        borderTopWidth:
          dividerType && dividerType === 'none'
            ? 0
            : index === 0 && dividerType && dividerType === 'line'
            ? 0
            : dividerType && dividerType === 'shadow'
            ? 1
            : 0,
        borderBottomWidth:
          dividerType && dividerType === 'none'
            ? 0
            : index !== findLastIndex(listData)
            ? 1
            : dividerType && dividerType === 'shadow'
            ? 1
            : 0,
        borderColor: '#e2e1e1',
        justifyContent: 'center',
        ...(dividerType && dividerType === 'shadow' && { borderRadius: 20 }),
      }}
      titleStyle={{
        //error font family________________
        fontFamily: checkFont(fontFamily),
        fontSize,
        color,
      }}
      descriptionStyle={{
        //error font family________________
        fontFamily: checkFont(fontFamily),
        fontSize,
        color,
      }}
    />
  );

  const keyExtractor = (item: any, index: number) => index;

  const list: any = (
    <FlatList
      {...isFixedHeight}
      nestedScrollEnabled
      showsHorizontalScrollIndicator={false}
      data={listData}
      keyExtractor={keyExtractor}
      // ItemSeparatorComponent={() => <Divider />}
      renderItem={renderItemList}
      // Performance settings
      // removeClippedSubviews={true} // Unmount components when outside of window
      // initialNumToRender={5} // Reduce initial render amount
      // maxToRenderPerBatch={1} // Reduce number in each render batch
      // updateCellsBatchingPeriod={100} // Increase time between renders
      // windowSize={7} // Reduce the window size
      // RemoveClippedSubviews
      {...(isLoadMore && {
        ListFooterComponent: ListFooterComponent,
      })}
    />
  );

  return (
    <>
      <List.Section
        //@ts-ignore
        style={{
          ...styles,
          opacity,
          ...(listData && listData.length > 0 ? { backgroundColor } : {}),
          borderColor,
          borderWidth,
          borderRadius,
          // marginTop,
          fontSize,
          fontFamily: checkFont(fontFamily),
          color,
          ...(isWeb && {
            marginTop: 0,
            marginBottom: 0,
            height: attrs.height - 20,
            overflowX: 'hidden',
            overflowY: listData.length > 0 ? 'visible' : 'hidden',
          }),
        }}
      >
        {listHeader.enabled && <List.Subheader>Simple List</List.Subheader>}
        {/* {!listData && <EmptyList attributes={attrs} />} */}
        {listData &&
          listData.length > 0 &&
          (scrollActive && (Platform.OS !== 'web' || !isNil(target)) ? (
            <View style={{ height: attrs.height, margin: 0 }}>{list}</View>
          ) : (
            list
          ))}
      </List.Section>
    </>
  );
};

export default memo(SimpleList);
