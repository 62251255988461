import store from '@common/redux/store';
import { IAction } from '@common/types/';
import { ActionResponse } from '../excuteAction';
import { setValues } from '@common/redux/slice/formInput';
import { getTextBinding } from '../../handleBinding';

export const setInput: (
  action: IAction,
  currentRecord?: any,
  itemIndex?: number
) => Promise<ActionResponse> = async (
  action: IAction,
  currentRecord?: any,
  itemIndex?: number
) => {
  const dispatch = store.dispatch;

  action.options.inputId &&
    dispatch(
      setValues({
        values: {
          [action.options.inputId]: getTextBinding(
            action.options,
            currentRecord,
            undefined,
            itemIndex,
            undefined
          ),
        },
      })
    );

  return {
    status: 'SUCCEED',
  };
};

export default setInput;
