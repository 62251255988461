import React, { useState, FC, useEffect } from 'react';
import { View } from 'react-native';
import { Platform } from 'react-native';
import { useSelector } from 'react-redux';
import { getCurrentRecord } from '@common/redux/selectors/database';
import { useBinding } from '@common/hooks/useBinding';
import { get } from 'lodash';
import { WebView } from 'react-native-webview';
import createStyles from './style';

const Vimeo: FC = (attributes: any) => {
  const [videoIdPlay, setVideoIdPlay] = useState('');
  const styles = createStyles(attributes);
  const { handleBindingField } = useBinding();
  const getTableId = get(attributes, 'selectedItem.tableId');
  const currentRecord = useSelector(getCurrentRecord) || {};

  useEffect(() => {
    const url = handleBindingField(
      attributes?.url || '',
      attributes.record || currentRecord[getTableId],
      attributes?.selectedItem?.itemIndex
    );

    if (url) {
      const regExp =
        /^.*((com\/)(\d*[0-9]\d))/;
      const match = url.match(regExp);
      const videoId = match && match[3].length === 9 ? match[3]: '';
      setVideoIdPlay(videoId);
    }
  }, [currentRecord]);

  if (Platform.OS === 'web') {
    return (
      <View style={styles.container}>
        <iframe
          title="PMU"
          src={`https://player.vimeo.com/video/${videoIdPlay}?
          autoplay=1&
          loop=1&
          autopause=0`}
          width='100%' 
          height='100%' 
          allow="autoplay; fullscreen" 
        />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <WebView
        javaScriptEnabled={true}
        scrollEnabled={false}
        allowsFullscreenVideo={true}
        allowsInlineMediaPlayback={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        userAgent="Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_1 like Mac OS X) AppleWebKit/603.1.30 (KHTML, like Gecko) Version/10.0 Mobile/14E304 Safari/602.1"
        source={{
          uri: `https://player.vimeo.com/video/${videoIdPlay}?autoplay=1&loop=1&autopause=0`
        }}
        style={{
          width: attributes.width,
          height: attributes.height,
        }}
      />
    </View>
  );
};

export default Vimeo;
