import CreateButton from '@common/components/Button/ShareScreenButton/CreateAppButton';
import { HOME_URL } from '@common/constants/shared';
import { useParams } from '@common/routes/hooks';
import React, { useCallback, useEffect } from 'react';
import { SafeAreaView, View, Text, Platform } from 'react-native';
import IFrame from '@common/components/iframe';
import RenderAppDetail from '@common/components/RenderAppDetail';
import styles from './style';
import { WebView } from 'react-native-webview';
import { checkMobileLayout, copyToClipboard } from '@common/utils/functions';
import { createManifest, createAppleTouchIcon } from './SetupShare';
import useAppDetail from '@common/hooks/app';
import { useSelector } from 'react-redux';
import { dimensionSelector } from '@common/redux/selectors/page';
import appConfig from '../../../appConfig.json';
import ShareCustomDomain from './ShareScreenCustomDomain';
import useDebug from '@common/hooks/useDebug';

const subDomainBlockList = [
  'app',
  'lp',
  'news',
  'community',
  'admin',
  'share',
  'dev',
  'developer',
  'application',
  'sample',
  'test',
  'click',
  'nocode',
  'nocodejapan',
  'ncj',
];

const ShareScreenView = ({ appId }: any) => {
  const { isLoading, data } = useAppDetail({
    appId: appId,
    isShareScreen: true,
  });
  const device = 'iphonePro';
  const dimension = useSelector(dimensionSelector);
  const isMobile = checkMobileLayout(dimension);
  const isDebug = useDebug();

  const handleClickCreate = useCallback(() => {
    window.open(HOME_URL, '_blank');
  }, []);

  const handleCopy = useCallback(() => {
    copyToClipboard(window.location.href);
  }, []);

  const onClickCloneApp = () => {
    if (appId) {
      const url = `${appConfig?.clientUrl || ''}/view/${appId}`;
      window.open(url, '_blank');
    }
  };

  useEffect(() => {
    async function setupManifest() {
      const manifestEl = document.querySelector("link[rel='manifest']");
      const appleTouchIconEl = document.querySelector(
        "link[rel='apple-touch-icon']"
      );

      const maniFestHref = createManifest(appId, data?.name, data?.icon);
      const appleTouchIcon = createAppleTouchIcon(data?.icon);
      manifestEl?.setAttribute('href', await maniFestHref);
      appleTouchIconEl?.setAttribute('href', appleTouchIcon);
      document.title = data?.name || '';
    }
    setupManifest();
  }, [appId, data]);

  if (isMobile) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    let newUri = `/preview/${appId}?hiddenStatusBar=true`;
    if (urlSearchParams.has('locale')) {
      const locale = urlSearchParams.get('locale');
      newUri = newUri + `&locale=${locale}` + `&pwa=true`;
    }
    if (urlSearchParams.has('code') && urlSearchParams.has('type')) {
      const code = urlSearchParams.get('code');
      const type = urlSearchParams.get('type');
      newUri = newUri + `&code=${code}&type=${type}`;
    }
    if (urlSearchParams.has('masterId')) {
      const masterId = urlSearchParams.get('masterId');
      newUri = newUri + `&masterId=${masterId}`;
    }
    if (urlSearchParams.has('login_url')) {
      const login_url = urlSearchParams.get('login_url') ?? '';
      newUri = newUri + `&login_url=${encodeURIComponent(login_url)}`;
    }
    if (isDebug) {
      newUri = newUri + '&debug=true';
    }
    return <WebView source={{ uri: newUri }} />;
  }
  const scale = (dimension.height - 2 * 30) / (812 + 2 * 20);

  let newUri = `/preview/${appId}`;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const isTutorial = urlSearchParams.get('tutorial');

  if (urlSearchParams.has('locale')) {
    const locale = urlSearchParams.get('locale');
    newUri = `/preview/${appId}?locale=${locale}`;
  }

  if (isDebug) {
    newUri = newUri + '&debug=true';
  }

  return !data ? null : data.platform === 'web' ? (
    <View>
      <WebView
        source={{ uri: newUri }}
        style={{
          width: '100vw',
          height: '100vh',
        }}
        scalesPageToFit={true}
      />
    </View>
  ) : (
    <SafeAreaView style={styles.container}>
      <View style={styles.container}>
        {!isTutorial ? (
          <RenderAppDetail
            onClickCloneApp={onClickCloneApp}
            appId={appId}
            data={data}
            isLoading={isLoading}
          />
        ) : (
          <View></View>
        )}
        <View style={{ transform: [{ scale: scale }] }}>
          <IFrame device={device} appId={appId} />
        </View>
        {!isTutorial && (
          <View style={styles.selectWrapper}>
            <CreateButton onClick={handleClickCreate}>
              Create APP With Click
            </CreateButton>
          </View>
        )}
      </View>
    </SafeAreaView>
  );
};

const ShareScreen = () => {
  const currentHostName = window.location.hostname;
  const regexHost = /(\.click\.dev|\.gotravel\.city)$/;
  let subDomain =
    regexHost.test(currentHostName) && currentHostName.replace(regexHost, '');

  const isMobile = () => {
    try {
      document.createEvent('TouchEvent');
      return true;
    } catch (e) {
      return false;
    }
  };
  const params = useParams();

  if (Platform.OS == 'web') {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const customDomain = urlSearchParams.get('domain');

    const isState = urlSearchParams.has('state');
    const state = urlSearchParams.get('state');
    if (isState) {
      const codeAuth = urlSearchParams.get('code');
      if (state && navigator.userAgent.indexOf('Line/') !== -1) {
        let path: any = state;
        if (state.includes('/preview')) {
          path = path.substring(8) + `?code=${codeAuth}&type=line`;
        }
        window.location.href = window.location.origin + path;
        return null;
      }
      if (isMobile()) {
        const launchApp = () => {
          window.location.replace(`${state}://?code=${codeAuth}`);
        };
        if (state && codeAuth) launchApp();
        return null;
      }
    }

    if (!params?.appId) {
      if (customDomain) {
        return <ShareCustomDomain domain={customDomain} />;
      }
      if (subDomain && !subDomainBlockList.includes(subDomain)) {
        return <ShareCustomDomain domain={subDomain} />;
      }
      return (
        <View>
          <Text>Router not found</Text>
        </View>
      );
    }
    return <ShareScreenView appId={params.appId} />;
  } else {
    return (
      <View>
        <Text>Cannot support for OS version</Text>
      </View>
    );
  }
};

export default ShareScreen;
