import { ICardList } from '@nocode/types';
import { get, isEmpty } from 'lodash';
import queryString from 'query-string';
import React, { useState } from 'react';
import { Platform, Text, TouchableOpacity, View } from 'react-native';
import EmptyList from '../EmptyList';
import SkeletonLoadingCard from '../SkeletonLoading/CardListPlaceholder';
import CardItem from './Card';
import { getItemList } from './func';

const CardList = (attrs: ICardList) => {
  const data = getItemList(attrs);
  const tableId = get(attrs, 'attributes.items.source.tableId');

  if (attrs.initializeList) {
    return <SkeletonLoadingCard attrs={attrs} initialize={true} />;
  }

  const [page, setPage] = useState(1);
  const { totalPage } = attrs;
  const isShowFooter = totalPage > page;

  const search = !isEmpty(window)
    ? queryString.parse(window?.location?.search)
    : {};

  const target = search?.target;

  // fix mobile app not show warning is not selected table
  const emptyList = (target && !tableId) || (!tableId && Platform.OS !== 'web');

  if (emptyList)
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          zIndex: attrs.zIndex,
        }}
      >
        {data.length <= 0 && <EmptyList attributes={attrs} />}
      </View>
    );

  const ListFooterComponent = () => {
    return (
      <TouchableOpacity
        onPress={() => {
          if (totalPage > page) {
            setPage(page + 1);
            attrs.onLoadMoreFilter && attrs.onLoadMoreFilter(page + 1);
          }
        }}
      >
        {attrs.loadingFilter ? (
          <SkeletonLoadingCard attrs={attrs} initialize={false} />
        ) : (
          <Text
            style={{
              fontSize: 14,
              textDecorationLine: 'underline',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Load more
          </Text>
        )}
      </TouchableOpacity>
    );
  };

  return (
    <>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          zIndex: attrs.zIndex,
          ...(attrs.isWeb && {
            height: attrs.height,
            overflowX: 'hidden',
            overflowY: 'visible',
          }),
        }}
      >
        {data.map((item: any, index: number) => {
          return (
            <CardItem
              item={item}
              key={index}
              index={index}
              attrs={attrs}
              onPress={attrs.onPress}
              isWeb={attrs.isWeb}
            />
          );
        })}
      </View>

      {isShowFooter ? <ListFooterComponent /> : <React.Fragment />}
    </>
  );
};

export default CardList;
