import { BINDING_SELECTOR_TYPE } from '@common/constants/shared';
import {
  getAvailability,
  getAvailabilityMultiConditional,
} from '@common/hooks/useVisibility';
import {
  actionCustomAction,
  setActionType,
} from '@common/redux/slice/customAction';
import { resetValue } from '@common/redux/slice/formInput';
import store from '@common/redux/store';
import history from '@common/routes/history';
import { IRecord } from '@common/types/';
import { MetaData } from '@common/types/action';
import { ActionTypes, IAction } from '@common/types/element';
import executeCustom from '@common/utils/handleActions/ActionItem/customAction';
import didLogin from '@common/utils/handleActions/ActionItem/didLogin';
import docomoLogin from '@common/utils/handleActions/ActionItem/docomoLogin';
import lineLogin from '@common/utils/handleActions/ActionItem/lineLogin';
import trustdockLogin from '@common/utils/handleActions/ActionItem/trustdockLogin';
import { getTextBinding } from '@common/utils/handleBinding';
import {
  filter,
  find,
  forEach,
  get,
  includes,
  isArray,
  isBoolean,
  isEmpty,
  isNil,
  map,
  omit,
  some,
  uniq,
} from 'lodash';
import qs from 'query-string';
import { getLastedRecordCreate } from '../database';
import { getResetValue } from '../functions';
import { flattenObj, isManyRoundRelaton } from '../handleBinding/function';
import { excuteRoundRelationship } from '../handleBinding/helps';
import appleLogin from './ActionItem/appleLogin';
import executeCheckVersion from './ActionItem/checkVersion';
import excuteCreateObject from './ActionItem/create';
import excuteDeleted from './ActionItem/delete';
import forgotPassword from './ActionItem/forgotPassword';
import excuteLogout from './ActionItem/logout';
import executeNavigate from './ActionItem/navigate';
import pushNotfication from './ActionItem/pushNotification';
import sendEmail from './ActionItem/sendEmail';
import setInput from './ActionItem/setInput';
import excuteSignin from './ActionItem/signin';
import excuteSignup from './ActionItem/signup';
import trackingAnalytics from './ActionItem/trackingAnalytics';
import excuteUpdateObject from './ActionItem/update';
import executeWebLink from './ActionItem/webLink';
import {
  getMappingFieldsAction,
  getUserLoginRecord,
  getValueChangeAction,
} from './func/func';

const hasValueChangeAction = [
  'createObject',
  'updateObject',
  'signin',
  'signup',
  'updateLoggedIn',
  'logout',
];

const blockAction = ['signin', 'signup', 'logout'];

export const getCurrentRelationship = (
  source: Record<string, any>,
  fieldId: string,
  tableId: string,
  listItem?: Record<string, any>,
  options?: any
) => {
  const { selector } = source;
  const state: any = store.getState();
  const targetTable = state.database.dataSource[tableId];

  let targetId: string;
  let targetRecord: Record<string, any>;

  if (isManyRoundRelaton(options)) {
    const newSource = omit(options, ['dataType']);
    const flattenSource = flattenObj(newSource);

    return excuteRoundRelationship(newSource, flattenSource, null);
  }

  switch (selector.type) {
    case BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR:
      const userLoginRecord: IRecord | any = getUserLoginRecord();
      targetId = userLoginRecord[fieldId];
      targetRecord = find(targetTable, { _id: targetId });
      return targetRecord;

    case BINDING_SELECTOR_TYPE.ROUTE_PARAM_SELECTOR:
      const currentRecord = get(
        state,
        `database.currentRecord[${source.tableId}]`,
        {}
      );
      targetId = currentRecord.record[fieldId];
      targetRecord = find(targetTable, { _id: targetId });
      return targetRecord;

    case BINDING_SELECTOR_TYPE.LIST_ITEM_SELECTOR:
      if (listItem) {
        targetId = listItem.record[fieldId];
        targetRecord = find(targetTable, { _id: targetId });
        return targetRecord;
      }
      return {};

    case BINDING_SELECTOR_TYPE.CREATED_OBJECT:
      const created = getLastedRecordCreate(source.tableId);
      targetId = created.record[fieldId];
      targetRecord = find(targetTable, { _id: targetId });
      return targetRecord;

    default:
      break;
  }
};

export type ActionResponse = {
  status: string;
  message?: string;
  target?: any;
  current?: any;
};

export const getRelationshipField = (databaseUuid: string) => {
  const state: any = store.getState();

  const database = state.database.database;
  const appInfo = state.appInfo.app;

  const targetTable = find(database, { databaseUuid });

  const relatedTable =
    targetTable &&
    filter(
      map(
        targetTable.fields,
        (item: Record<string, any>) =>
          !isArray(item.type) &&
          typeof item.type === 'object' &&
          item.type.tableId
      ),
      (i) => !isBoolean(i)
    );

  const result = uniq([...relatedTable, targetTable.databaseUuid]);

  return {
    tables: result || [],
    appId: appInfo?.id,
  };
};

const isOAuthAction = (action: ActionTypes) => {
  return (
    action === ActionTypes.LINE_LOGIN ||
    action === ActionTypes.DOCOMO_LOGIN ||
    action === ActionTypes.DID_LOGIN ||
    action === ActionTypes.TRUSTDOCK_LOGIN ||
    action === ActionTypes.APPLE_LOGIN ||
    action === ActionTypes.SIGNUP ||
    action === ActionTypes.SIGNIN
  );
};

export const actionPromise = async (
  actionComponent: IAction[],
  currentRecord?: any,
  itemIndex?: number,
  locale?: string,
  isForm?: boolean,
  externalIndex?: string,
  externalUpdateId?: string,
  externalRecord?: any,
  parentRecordList?: any,
  formId?: string
) => {
  let childrenData: any = currentRecord;
  if (!actionComponent) return;
  const dispatch = store.dispatch;
  const state: any = store.getState();
  const listSchemeLink = JSON.parse(
    `${get(state, 'appInfo.app.metadata', [])}`
  )?.listSchemeLink;

  const query = qs.parse(history.location.search);
  const { target } = query;

  const hasAuthAction = !isEmpty(
    actionComponent.filter(
      (action) =>
        action.actionType === ActionTypes.SIGNIN ||
        action.actionType === ActionTypes.SIGNUP ||
        action.actionType === ActionTypes.LINE_LOGIN ||
        action.actionType === ActionTypes.DOCOMO_LOGIN ||
        action.actionType === ActionTypes.DID_LOGIN ||
        action.actionType === ActionTypes.TRUSTDOCK_LOGIN ||
        action.actionType === ActionTypes.APPLE_LOGIN
    )
  );

  const result: any[] = [];
  let inputValues: Record<string, any> = {};
  let isStopAction = false;

  for (let i = 0; i < actionComponent.length; i++) {
    const {
      options: { conditional, conditionals },
      actionType,
    } = actionComponent[i];

    if (!isNil(conditional)) {
      const isActionAvailable = getAvailability(conditional, currentRecord);
      if (!isActionAvailable) continue;
    }

    if (conditionals && conditionals.length > 0) {
      const isActionAvailable = some(conditionals, (condition) => {
        if (isArray(condition)) {
          return getAvailabilityMultiConditional(
            condition,
            currentRecord,
            parentRecordList
          );
        }

        return getAvailability(condition, currentRecord);
      });

      if (!isActionAvailable) continue;
    }

    if (
      (ActionTypes.EXTERNAL_LINK === actionType && isStopAction === true) ||
      (ActionTypes.NAVIGATE === actionType && isStopAction === true)
    )
      continue;

    const cloneFields =
      actionComponent[i].options.fields &&
      actionComponent[i].options.fields?.filter((item: any) => !isNil(item));

    const mappingField = !hasValueChangeAction.includes(
      actionComponent[i].actionType
    )
      ? {}
      : getMappingFieldsAction(cloneFields, itemIndex);

    const mapCurrent = (currentRecord: any[]) => {
      let objectCurrent: Record<string, any> = {};

      forEach(currentRecord, (item: Record<string, any>) => {
        if (isNil(item) || isEmpty(item)) return;
        const { databaseId, ...rest } = item;

        objectCurrent = {
          ...omit(objectCurrent, [`${databaseId}`]),
          [databaseId]: { ...rest },
        };
      });
      return objectCurrent;
    };

    const currentObj = isArray(currentRecord)
      ? mapCurrent(currentRecord)
      : currentRecord;

    const valueChange = await getValueChangeAction(
      cloneFields,
      actionComponent[i].options.tableId,
      actionComponent[i].options?.selector?.type,
      currentObj,
      itemIndex,
      actionComponent[i].options
    );

    const optionsNofitication = {
      ...actionComponent[i].options,
      filter: {
        ...actionComponent[i].options.filter,
        comparison: getTextBinding(
          get(actionComponent[i], 'options.filter.comparison', null),
          currentRecord,
          undefined,
          undefined,
          undefined,
          true
        ),
        ...(get(actionComponent[i], 'options.filter.comparison2') && {
          comparison2: getTextBinding(
            get(actionComponent[i], 'options.filter.comparison2', null),
            currentRecord,
            undefined,
            undefined,
            undefined,
            true
          ),
        }),
      },
      body: getTextBinding(
        get(actionComponent[i], 'options.body', ''),
        currentRecord
      ),
      title: getTextBinding(
        get(actionComponent[i], 'options.title', ''),
        currentRecord
      ),
      ...(!isEmpty(get(actionComponent[i], 'options.address', ''))
        ? {
            address: getTextBinding(
              get(actionComponent[i], 'options.address', ''),
              currentRecord
            ),
          }
        : {}),
    };

    const record = !hasValueChangeAction.includes(actionComponent[i].actionType)
      ? {}
      : valueChange;

    const handleResetValue = () => {
      const currentValue =
        isForm && formId
          ? Object.keys(record).reduce(
              (att: any, curr: string) => ({
                ...att,
                [`${formId}-${curr}`]: record[curr],
              }),
              {}
            )
          : record;

      dispatch(resetValue(currentValue));
    };

    let res: ActionResponse = await runAction(
      actionComponent[i],
      {
        record,
        mappingField,
        databaseUuid: get(actionComponent[i], 'options.tableId', ''),
        hasAuthAction,
        lastLocation: target,
        autoValues: actionComponent[i].autoValues,
        optionsNofitication,
        handleResetValue,
      },
      childrenData,
      actionComponent,
      itemIndex,
      locale,
      externalIndex,
      externalUpdateId,
      externalRecord,
      listSchemeLink
    );

    dispatch(
      setActionType({
        actionType: actionComponent[i].actionType,
        databaseId: get(actionComponent[i], 'options.tableId', ''),
      })
    );

    if (isOAuthAction(actionType) && res.status !== 'SUCCEED') return res;

    if (ActionTypes.FORGOT_PASSWORD === actionType && res.status !== 'SUCCEED')
      return res;

    if (
      (ActionTypes.EXTERNAL_LINK === actionType && res.status === 'SUCCEED') ||
      (ActionTypes.NAVIGATE === actionType && res.status === 'SUCCEED')
    )
      isStopAction = true;

    if (
      includes(
        [
          ActionTypes.DELETE_OBJECT,
          // ActionTypes.UPDATE_OBJECT,
          ActionTypes.CREATE_OBJECT,
        ],
        actionType
      )
    ) {
      const { target, current } = res;
      if (target?._id === current?._id) {
        childrenData = {};
      }
    }

    if (
      res.status === 'FAILED' &&
      includes(blockAction, actionComponent[i].actionType) &&
      isForm
    )
      return res;

    result.push(res);

    inputValues = {
      ...inputValues,
      ...mappingField,
    };

    const resetInputs = getResetValue(inputValues);
    const condition = resetInputs && Object.keys(resetInputs).length;

    // Reset field input && fix bug CLICKNEWCANVAS-629
    if (condition) {
      dispatch(resetValue(resetInputs));
    }
  }

  // // Reset field input
  // if (inputValues && Object.keys(inputValues).length) {
  //   dispatch(resetValue(inputValues));
  // }

  dispatch(
    actionCustomAction({
      actionCustomActions: [],
    })
  );

  return result[result.length - 1];
};

const runAction: (
  action: IAction,
  metadata: MetaData,
  currentRecord?: any,
  actionComponent?: IAction[],
  itemIndex?: number,
  locale?: string,
  externalIndex?: string,
  externalUpdateId?: string,
  externalRecord?: any,
  listSchemeLink?: any
) => Promise<ActionResponse> = async (
  action: IAction,
  metadata: MetaData,
  currentRecord?: any,
  actionComponent?: IAction[],
  itemIndex?: number,
  locale?: string,
  externalIndex?: string,
  externalUpdateId?: string,
  externalRecord?: any,
  listSchemeLink?: any
) => {
  const { actionType } = action;

  switch (actionType) {
    //create object action___________
    case ActionTypes.CREATE_OBJECT:
      return await excuteCreateObject(metadata, currentRecord, locale);

    //update logged user or update object action___________
    case ActionTypes.UPDATE_OBJECT:
      return await excuteUpdateObject(action, metadata, currentRecord, locale);

    //delete Object action___________
    case ActionTypes.DELETE_OBJECT:
      return await excuteDeleted(action, metadata, currentRecord, locale);

    // signin user action___________
    case ActionTypes.SIGNIN:
      return await excuteSignin(action, metadata, locale);

    //signup user action___________
    case ActionTypes.SIGNUP:
      return await excuteSignup(action, metadata, locale);

    //Logout user
    case ActionTypes.LOGOUT:
      return await excuteLogout(action);

    //External link
    case ActionTypes.EXTERNAL_LINK:
      return await executeWebLink(
        action,
        currentRecord,
        itemIndex,
        listSchemeLink
      );

    //Set input value
    case ActionTypes.SET_INPUT_VALUE:
      return setInput(action, currentRecord, itemIndex);

    //Line Login
    case ActionTypes.LINE_LOGIN:
      return await lineLogin(action, currentRecord, actionComponent);

    //Docomo Login
    case ActionTypes.DOCOMO_LOGIN:
      return await docomoLogin(action, currentRecord, actionComponent);

    //DID Login
    case ActionTypes.DID_LOGIN:
      return await didLogin(action, currentRecord, actionComponent);

    //Trustdock Login
    case ActionTypes.TRUSTDOCK_LOGIN:
      return await trustdockLogin(action, currentRecord, actionComponent);

    //Check version
    case ActionTypes.CHECK_VERSION:
      return await executeCheckVersion(action);

    //execute custom action
    case ActionTypes.CUSTOM:
      return await executeCustom(action);

    //forgot password
    case ActionTypes.FORGOT_PASSWORD:
      return await forgotPassword(action, currentRecord, actionComponent);

    //Apple login
    case ActionTypes.APPLE_LOGIN:
      return await appleLogin(action, currentRecord, actionComponent, locale);

    //Push notification
    case ActionTypes.PUSH_NOTIFICATION:
      return await pushNotfication(action, metadata, locale);

    //Send email
    case ActionTypes.SEND_EMAIL:
      return await sendEmail(action, metadata, locale);

    //Tracking Event
    case ActionTypes.TRACKING_ANALYSTIC:
      return trackingAnalytics(action, currentRecord, itemIndex);

    //navigate action________________
    default:
      return await executeNavigate(
        action,
        metadata,
        currentRecord,
        externalIndex,
        externalUpdateId,
        externalRecord
      );
  }
};
