import api from '@common/configs/api';
import { useEffect, useState } from 'react';

export type ReceivedProps = {
  domain: string;
};

const useShareCustomDomain = (props: ReceivedProps) => {
  const [loading, setLoading] = useState(true);
  const [app, setApp] = useState<any>();
  const { domain } = props;
  const getAppbyDomain = async () => {
    try {
      const response = await api({
        method: 'get',
        url: `v2/apps-domain/${domain.replace(/^https?:\/*/, '')}`,
      });

      setApp(response.data as any);
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  const url = `/preview/${app?.appid}?locale=ja&hiddenStatusBar=true`;

  useEffect(() => {
    getAppbyDomain();
  }, []);
  return {
    ...props,
    app,
    url,
    loading,
  };
};

export type Props = ReturnType<typeof useShareCustomDomain>;

export default useShareCustomDomain;
