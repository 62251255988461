import useValueInputs, {
  UseValueInputProps,
} from '@common/hooks/useValueInputs';
import { IPassword } from '@common/types/element';
import React, { FC, useCallback } from 'react';
import { TextInput, TouchableOpacity } from 'react-native';
import createStyles from './style';

const Password: FC<IPassword> = (attributes) => {
  const { changeInput, valueInputs }: UseValueInputProps =
    useValueInputs(attributes);

  const styles = createStyles(attributes);

  const key: any = attributes.id;

  const handleChange = useCallback(
    (value: string) => {
      changeInput(value);
    },
    [key]
  );
  const checkPlaceholder = (value: any) => {
    if (Array.isArray(value)) {
      return value[0];
    } else {
      return value;
    }
  };

  return (
    <TouchableOpacity activeOpacity={1} style={styles.container}>
      <TextInput
        onChangeText={handleChange}
        value={valueInputs}
        style={[
          styles.text,
          { display: 'flex', alignSelf: 'center', textAlignVertical: 'center' },
        ]}
        placeholder={checkPlaceholder(attributes.placeholder)}
        placeholderTextColor={attributes.placeholderColor}
        secureTextEntry={true}
      />
    </TouchableOpacity>
  );
};

export default Password;
