import { getValueFields } from '@common/redux/selectors/formInput';
import store from '@common/redux/store';
import { getTextBinding } from '@common/utils/handleBinding';
import compare from '@common/utils/compare';
import { getSourceRecord, getSourceTableUuid } from '@common/utils/functions';
import { getTargetRecord } from '@common/utils/handleActions/ActionItem/update';
import { every, get, isArray, isEmpty, isNil, isString, some } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const getAvailabilityMultiConditional = (
  conditionals: any[],
  currentRecord?: Record<string, any>,
  parentRecordList?: any
) => {
  if (!conditionals.length) return false;

  return every(conditionals, (conditional) => {
    if (!conditional.source) return true;

    return getAvailability(conditional, currentRecord, parentRecordList);
  });
};

export const getAvailability = (
  visibilityConfig: Record<string, any>,
  record?: Record<string, any>,
  parentRecordList?: any
) => {
  if (isNil(visibilityConfig)) return true;
  const state: any = store.getState();
  const currentRecord = state.database.currentRecord;

  const { comparator, comparison, comparison2, source } = visibilityConfig;
  const srcTableId = getSourceTableUuid(source);
  const targetTableId = get(comparison, 'source.tableId', undefined);
  const secondTargetTableId = get(comparison2, 'source.tableId', undefined);

  if (isNil(source)) return true;
  const targetRecord = (current: any, databaseId: string) => {
    return isArray(current)
      ? getTargetRecord(current, databaseId)
      : !isEmpty(current[databaseId])
      ? current[databaseId]
      : current;
  };

  //config for nested list
  const srcRecord = getSourceRecord(
    parentRecordList,
    source,
    targetRecord(record, srcTableId) || currentRecord[srcTableId]
  );

  const src = getTextBinding(
    {
      source,
      visibility: !isNil(visibilityConfig) || !isEmpty(visibilityConfig),
      type: 'binding',
    },
    srcRecord
  );

  const target = isString(comparison)
    ? comparison
    : getTextBinding(
        {
          source: comparison,
          type: 'binding',
        },
        targetRecord(record, targetTableId)
          ? targetRecord(record, targetTableId)
          : currentRecord[targetTableId]
      );

  const secondTarget = isString(comparison2)
    ? comparison2
    : getTextBinding(
        {
          source: comparison2,
          type: 'binding',
        },
        targetRecord(record, secondTargetTableId)
          ? targetRecord(record, secondTargetTableId)
          : currentRecord[secondTargetTableId]
      );

  return compare({
    value: src,
    comparator,
    comparison: target,
    comparison2: secondTarget,
    dataType: source.dataType,
  });
};

export const isTwoDirectionArray = (array: any[]) =>
  every(array, (item: any) => isArray(item));

const useAvailability = (
  visibilityConfig: any,
  record?: Record<string, any>,
  parentRecordList?: any
) => {
  const [available, setAvailable] = useState(false);
  const objectBinding = useSelector(getValueFields);

  const runAction = useCallback(async () => {
    if (visibilityConfig) {
      const config =
        isTwoDirectionArray(visibilityConfig) || !isArray(visibilityConfig)
          ? visibilityConfig
          : [[...visibilityConfig]];

      if (isArray(config)) {
        const availability = some(config, (option) => {
          if (isArray(option)) {
            return getAvailabilityMultiConditional(
              option,
              record,
              parentRecordList
            );
          }
          return getAvailability(option, record);
        });

        setAvailable(availability);
      } else {
        const availability = getAvailability(config, record);
        setAvailable(availability);
      }
    } else {
      setAvailable(true);
    }
  }, [visibilityConfig, record]);

  useEffect((): any => {
    runAction();

    return () => setAvailable(true);
  }, [objectBinding, visibilityConfig]);

  return available;
};

export default useAvailability;
