import {
  BINDING_SELECTOR_TYPE,
  ONE_DAY,
  PERMISSON,
} from '@common/constants/shared';
import { getValueFields } from '@common/redux/selectors/formInput';
import store from '@common/redux/store';
import { ICollection } from '@common/types/database';
import { Obj } from '@common/types/element';
import { getTextBinding } from '@common/utils/handleBinding';
import { flattenObj } from '@common/utils/handleBinding/function';

import { getCollection, getFieldType } from '@common/utils/database';
import { bindSourceHealthy } from '@common/utils/functions';
import axios from 'axios';
import { isString } from 'formik';
import {
  find,
  first,
  flatten,
  get,
  groupBy,
  isArray,
  isEmpty,
  isNil,
  isUndefined,
  map,
  mapKeys,
  omit,
  round,
  uniq,
} from 'lodash';
import moment from 'moment';
import {
  excuteRoundRelationship,
  getFieldRelationValue,
} from '@common/utils/handleBinding/helps';

export const AUTHEN_KEY = 'user';

const getTextBindingOptions = (options: any, fieldId: string) => {
  const newSource = omit(options, ['dataType']);
  const flattenSource = flattenObj(newSource);

  const recordRelation = excuteRoundRelationship(
    newSource,
    flattenSource,
    null
  );

  return getFieldRelationValue(fieldId, recordRelation) || [];
};

export const getValueChangeAction = async (
  fields: any,
  tableId?: string,
  selectorType?: string,
  currentData?: any,
  itemIndex?: number,
  options?: any
) => {
  const state: any = store.getState();

  let result: Obj = {};

  const valuesInput = getValueFields(state);

  if (!fields || fields.length == 0) {
    return result;
  }

  for (let field of fields) {
    const { fieldId, source: originSource } = field;

    let source;

    if (originSource) {
      if (originSource?.type == 'formula') {
        source = {
          ...originSource,
          formula: await bindSourceHealthy(originSource?.formula),
        };
      } else {
        source = (await bindSourceHealthy(originSource)) as any;
      }
    } else {
      source = field.source;
    }

    const currentRecord =
      isNil(currentData) || isEmpty(currentData)
        ? state.database.currentRecord
        : currentData;

    const profile = state.auth.profile;

    const createdRecord =
      (tableId && state.database.recordCreated[tableId]) || {};

    const getFieldId: any = source?.fieldId;

    if (typeof source === 'boolean') {
      result[fieldId] = source;
      continue;
    }

    const sourceInfos = isArray(source)
      ? source.map((item) => {
          if (typeof item === 'object') {
            return {
              ...item,
              fieldId: getFieldId,
              tableId: item.source?.source?.tableId,
              visibility: true,
            };
          }

          return item;
        })
      : {
          ...source,
          fieldId: getFieldId,
        };

    const sourceValue = source
      ? getTextBinding(
          sourceInfos,
          !isEmpty(currentRecord) ? currentRecord : currentData || {},
          true,
          itemIndex
        )
      : null;

    let fieldType = tableId ? getFieldType(tableId, fieldId) : {};

    result[fieldId] =
      !isNil(sourceValue) || !isEmpty(sourceValue)
        ? sourceValue
        : valuesInput[
            options?.formId ? `${options?.formId}-${fieldId}` : fieldId
          ];

    let valueField: any[] = [];

    if (source?.selector?.type == BINDING_SELECTOR_TYPE.ROUTE_PARAM_SELECTOR) {
      const value = get(
        currentRecord,
        !isEmpty(currentData)
          ? `record[${fieldId}]`
          : `${tableId}.record[${fieldId}]`,
        []
      );

      const valueAssociation = !isEmpty(value)
        ? get(createdRecord, `record[${fieldId}]`)
        : value;

      valueField = !valueAssociation
        ? []
        : isString(valueAssociation)
        ? [valueAssociation]
        : valueAssociation;
    } else if (
      source?.selector?.type === BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR
    ) {
      const result =
        source?.options?.operation === 'createAssociation'
          ? get(currentRecord, '_id')
            ? [get(currentRecord, '_id')]
            : get(currentRecord, `${source.tableId}._id`)
            ? [get(currentRecord, `${source.tableId}._id`)]
            : []
          : get(currentRecord, `record.${fieldId}`, []);

      valueField =
        isEmpty(result) && source?.options?.operation !== 'createAssociation'
          ? get(profile, 'userId', [])
          : result;
    } else {
      if (isEmpty(createdRecord?.record)) {
        valueField =
          (currentRecord?.record && currentRecord?.record[fieldId]) || [];
      } else {
        valueField =
          (createdRecord?.record && createdRecord?.record[fieldId]) || [];
      }
    }

    if (['manyToMany'].includes(fieldType?.type)) {
      if (
        isEmpty(sourceValue) &&
        source?.options?.operation === 'createAssociation'
      ) {
        result = omit(result, [fieldId]);
      }

      if (sourceValue && source?.options?.operation === 'createAssociation') {
        if (selectorType === BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR) {
          // const valueFieldCreate = profile[fieldId] || [];

          const auth = state.database.dataSource;
          const getAuth = get(auth, `${profile?.databaseId}`, []).find(
            (item: any) => item?._id === profile?.userId
          );

          const valueFieldCreate = get(getAuth, `record.${fieldId}`, []);

          result[fieldId] = uniq([...valueFieldCreate, sourceValue]);
        } else if (selectorType === BINDING_SELECTOR_TYPE.LIST_ITEM_SELECTOR) {
          const valueFieldCreate = currentRecord?.record
            ? get(currentRecord, `record.${fieldId}`, [])
            : get(currentRecord, `${tableId}.record.${fieldId}`, []);

          result[fieldId] = uniq([...valueFieldCreate, sourceValue]);
        } else if (
          selectorType === BINDING_SELECTOR_TYPE.ROUTE_PARAM_SELECTOR
        ) {
          const getCurrentRecord = get(
            state.database.currentRecord,
            `${tableId}.record.${fieldId}`
          );

          result[fieldId] = uniq([...(getCurrentRecord || []), sourceValue]);
        } else {
          if (
            options?.source?.selector?.type ===
              BINDING_SELECTOR_TYPE.ROUTE_PARAM_SELECTOR &&
            options?.type === 'belongsToRelation'
          ) {
            const getCurrentRecord = get(
              currentRecord,
              `${options?.source?.tableId}`
            );

            const targetId = get(
              getCurrentRecord,
              `record.${options?.fieldId}`
            );

            const targetTable = get(
              state.database.dataSource,
              `${options?.tableId}`,
              []
            ).find((item: any) => item?._id === targetId);

            const result = get(targetTable, `record.${fieldId}`, []);

            valueField = result;
          } else {
            valueField = getTextBindingOptions(options, fieldId);
          }

          result[fieldId] = uniq([...valueField, sourceValue]);
        }
      } else if (source?.options?.operation === 'deleteAssociation') {
        if (selectorType === BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR) {
          // const valueFieldFilter = profile[fieldId] || [];

          const auth = state.database.dataSource;
          const getAuth = get(auth, `${profile?.databaseId}`, []).find(
            (item: any) => item?._id === profile?.userId
          );

          const valueFieldFilter = get(getAuth, `record.${fieldId}`, []);

          result[fieldId] = valueFieldFilter.filter(
            (o: any) => o !== sourceValue
          );
        } else if (selectorType === BINDING_SELECTOR_TYPE.LIST_ITEM_SELECTOR) {
          const valueFieldCreate = currentRecord?.record
            ? get(currentRecord, `record.${fieldId}`)
            : get(currentRecord, `${tableId}.record.${fieldId}`);

          result[fieldId] = valueFieldCreate.filter(
            (o: any) => o !== sourceValue
          );
        } else if (
          selectorType === BINDING_SELECTOR_TYPE.ROUTE_PARAM_SELECTOR
        ) {
          const valueFieldFilter =
            get(currentRecord, `${tableId}.record.${fieldId}`) ||
            get(state.database.currentRecord, `${tableId}.record.${fieldId}`) ||
            [];

          result[fieldId] = valueFieldFilter.filter(
            (o: any) => o !== sourceValue
          );
        } else {
          if (
            options?.source?.selector?.type ===
              BINDING_SELECTOR_TYPE.ROUTE_PARAM_SELECTOR &&
            options?.type === 'belongsToRelation'
          ) {
            const getCurrentRecord = get(
              currentRecord,
              `${options?.source?.tableId}`
            );

            const targetId = get(
              getCurrentRecord,
              `record.${options?.fieldId}`
            );

            const targetTable = get(
              state.database.dataSource,
              `${options?.tableId}`,
              []
            ).find((item: any) => item?._id === targetId);

            const result = get(targetTable, `record.${fieldId}`, []);

            valueField = result;
          } else {
            valueField = getTextBindingOptions(options, fieldId);
          }

          result[fieldId] = valueField.filter((o: any) => o !== sourceValue);
        }
      }
    }
    // });
  }

  return result;
};

export const getMappingFieldsAction = (
  fields: any,
  itemIndex: number | undefined
) => {
  const state: any = store.getState();

  let result: any = {};

  const valuesInput = getValueFields(state);

  map(fields, (item: any) => {
    const { source, fieldId } = item;

    if (isArray(source)) {
      const sourceMapping = source.find(
        (o) =>
          (o && o?.source?.objectId) ||
          (o && o?.source?.source?.objectId) ||
          (o && o?.source?.selector?.selectObjectId) ||
          (o && o?.source?.source?.selector?.selectObjectId)
      );

      const selectIdMapping = sourceMapping?.source?.source
        ? get(sourceMapping, 'source.source.selector', {})
        : get(sourceMapping, 'source.selector', {});

      let objectIdMapping =
        selectIdMapping?.type === 'SELECT_VALUE_SELECTOR'
          ? selectIdMapping?.selectObjectId
          : sourceMapping?.source?.objectId;

      if (isArray(objectIdMapping)) {
        objectIdMapping = objectIdMapping[objectIdMapping.length - 1];
      }
      // if (!isNil(itemIndex)) {
      //   objectIdMapping = `${objectIdMapping}__${itemIndex}`;
      // }
      if (objectIdMapping && valuesInput[objectIdMapping]) {
        result[objectIdMapping] = fieldId;
      }
    } else {
      if (source?.formula) {
        const sourceMapping = source?.formula?.find(
          (o: any) =>
            (o && o?.source?.objectId) || (o && o?.source?.source?.objectId)
        );

        let getObjId: any = {};

        const getSource = (params: Record<string, any>) => {
          if (isEmpty(params) || isNil(params)) return;

          if (params?.source?.objectId) {
            getObjId = params?.source;
            return;
          } else {
            getSource(params?.source);
          }
        };

        getSource(sourceMapping);

        if (!isEmpty(getObjId)) {
          result[getObjId?.objectId] = fieldId;
        }
      } else {
        const lastSource = first(flattenObj(source));
        const selectorType = get(lastSource, 'selector.type');
        const objectId =
          selectorType === 'SELECT_VALUE_SELECTOR'
            ? get(lastSource, 'selector.selectObjectId')
            : source?.objectId;

        result[objectId] = fieldId;
      }
    }
  });

  return result;
};

export const getCollectionMapper = () => {
  const state: any = store.getState();

  const collections: Array<ICollection> = get(state, 'database.database', []);

  return {
    getCollectionArray: collections,
    collectionObject: groupBy(collections, 'databaseUuid'),
  };
};

export const findCollectionUuid = (databaseUuid: string) => {
  const state: any = store.getState();

  const collections: Array<ICollection> = get(state, 'database.database', []);

  return find(collections, { databaseUuid });
};

export const getCollectionNameUuid = (atabaseUuid: string) => {
  const collection: ICollection | any = findCollectionUuid(atabaseUuid);

  return collection.databaseName || '';
};

export const getUserLoginRecord = () => {
  const state: any = store.getState();

  return get(state, 'auth.profile');
};

export const getFieldCollection = (databaseUuid: string) => {
  if (!databaseUuid) return [];

  const collection: ICollection | any = getCollection(databaseUuid);

  return collection?.fields || [];
};

export const parsePayload = (params: {
  databaseUuid: string;
  record: Record<string, any>;
  actionType?: string;
}) => {
  const state: any = store.getState();
  const { databaseUuid, record, actionType } = params;

  const fieldTable = state.database.database;

  const hasDatabaseUuid =
    isEmpty(databaseUuid) && actionType === 'signUpAction'
      ? fieldTable[0].databaseUuid
      : databaseUuid;

  const getFieldTable = find(
    fieldTable,
    (item) => item.databaseUuid === hasDatabaseUuid
  )?.fields;

  const table = find(
    fieldTable,
    (item) => item.databaseUuid === hasDatabaseUuid
  );

  let newRecord: any;

  mapKeys(record, (value, key) => {
    const getFieldId = getFieldTable?.find(
      (item: Record<string, any>) => item.fid === key
    );

    if (!getFieldId) return;

    const formatValue = (value: any) => {
      const isvalidMoment = moment(value).unix();
      const isMoment = moment(
        value,
        ['YYYY/MM/DD HH:mm', 'YYYY/MM/DD', 'YYYY/MM/DD HH:mm:ss'],
        true
      ).isValid();
      const isMomentValid =
        isMoment && isvalidMoment && isvalidMoment?.toString().length >= 10;

      const getFieldType = get(getFieldId, 'type.type');

      if (getFieldType === 'belongsTo') {
        if (isArray(value)) return value[0];

        return isEmpty(value) ? undefined : value;
      } else if (getFieldType === 'manyToMany') {
        return flatten(value);
      } else {
        switch (getFieldId.type) {
          case 'number':
            if (!value) return undefined;

            return isMomentValid || isNaN(+value)
              ? moment(value).unix()
              : +value;

          case 'date':
          case 'dateOnly':
            if (!isNaN(+value)) {
              return moment(round(+value * ONE_DAY)).toISOString();
            }
            return moment(value).toISOString();

          default:
            // const stringRegex = '\n';
            // const result =
            //   typeof value !== 'string'
            //     ? value
            //     : value.replace(stringRegex, '');

            return getFieldId?.type === 'string'
              ? isEmpty(value)
                ? null
                : value
              : value;
        }
      }
    };

    const valueField = isUndefined(value) ? null : formatValue(value);

    const isTableThirdParty =
      table && table.isThirdParty && getFieldId && getFieldId.key;

    if (isTableThirdParty) {
      newRecord = {
        ...newRecord,
        ...(!isNil(valueField)
          ? { [getFieldId.key.replace('[0].', '')]: valueField }
          : {}),
      };
    } else {
      newRecord = {
        ...newRecord,
        ...(!isNil(valueField) ? { [key]: valueField } : {}),
      };
    }
  });

  return newRecord;
};

export const buildObject = (
  key: string,
  value: any,
  object: any,
  keys: any[] = []
) => {
  if (key.includes('.')) {
    keys.push(key.substring(0, key.indexOf('.')));
    key = key.substring(key.indexOf('.') + 1, key.length);
    buildObject(key, value, object, keys);
  } else {
    keys.push(key);
    for (let index = 0; index < keys.length; index++) {
      const element = keys[index];
      if (index === keys.length - 1) {
        object[element] = value;
      } else if (element in object) {
        object = object[element];
      } else {
        object = object[element] = {};
      }
    }
  }
};

export const getUrlExternal = (
  metadata: any,
  endpoints: any,
  method: string,
  state: any
) => {
  let url = metadata?.baseURL ? JSON.parse(metadata.baseURL) : '';
  if (endpoints && Array.isArray(endpoints)) {
    endpoints.forEach((element: any) => {
      if (element.key === method && element.objectBinding) {
        url = '';
        const urlArr = JSON.parse(element.objectBinding);
        const input = getTextBinding(
          urlArr,
          !isEmpty(state.database.currentRecord)
            ? state.database.currentRecord.record
            : {}
        );
        url = input;
      }
    });
  }

  return url;
};

export const handlePermissonDatabase = ({
  databaseUuid,
  targetRecord,
  payload,
}: {
  databaseUuid: string;
  targetRecord: Record<string, any>;
  payload?: any;
}) => {
  const state: any = store.getState();
  const database = state.database.database;
  const auth = state.auth.profile;

  const collection = database.find((item: Record<string, any>) => {
    return item.databaseUuid === databaseUuid;
  });

  const fields = get(collection, 'fields', []);

  let record: Record<string, any> = {};
  let arrMess: string[] = [];
  let objKeys: string[] = [];

  const handleRes = (key: string, value: any) => {
    const field = fields.find((item: Record<string, any>) => item.fid === key);
    const permissionEdit = get(field, 'permission.edit', PERMISSON.ANYONE);

    const accObj = {
      [key]: value,
    };

    switch (permissionEdit) {
      case PERMISSON.NOBODY:
        return {};

      case PERMISSON.LOGGED_USER:
        const isLogged = !isEmpty(auth) || !isNil(auth);
        return isLogged ? accObj : {};

      case PERMISSON.RECORD_CREATOR:
        const isCreator =
          get(targetRecord, `creator`, null) === get(auth, 'email', '');
        return isCreator ? accObj : {};

      default:
        return accObj;
    }
  };

  mapKeys(payload, (value, key) => {
    const valueField = handleRes(key, value);

    record = {
      ...record,
      ...valueField,
    };

    if (isUndefined(record[key])) {
      objKeys.push(key);
    }
  });

  const handleMessage = () => {
    const filterField: any = fields
      .filter((i: any) => objKeys.includes(i.fid))
      .map((o: any) => ({
        name: o.name,
        permission: o?.permission?.edit,
      }));

    let groupByPermission = Object.values(
      filterField.reduce(
        (result: any, { permission, name }: any) => ({
          ...result,
          [permission]: result[permission]
            ? { permission, name: [...result[permission].name, name] }
            : { permission, name: [name] },
        }),
        {}
      )
    );

    for (let index = 0; index < groupByPermission.length; index++) {
      let mess: string = '';

      const permissionEdit = get(
        groupByPermission,
        `${[index]}.permission`,
        PERMISSON.ANYONE
      );
      const fieldName = get(groupByPermission, `${[index]}.name`, '');
      const string = fieldName.length >= 1 ? 'Fields' : 'Field';

      switch (permissionEdit) {
        case PERMISSON.NOBODY:
          mess = `${string} ${fieldName.join(', ')} nobody edit permission`;
          break;

        case PERMISSON.LOGGED_USER:
          mess = `${string} ${fieldName.join(
            ', '
          )} logged in user in has permission`;
          break;

        case PERMISSON.RECORD_CREATOR:
          mess = `${string} ${fieldName.join(
            ', '
          )} record creator in has permission`;
          break;

        default:
          mess = '';
          break;
      }
      arrMess = [...arrMess, mess];
    }
  };

  handleMessage();

  return {
    message: !isEmpty(arrMess) ? uniq(arrMess).join(' and ') + ' !' : undefined,
    payload: record,
  };
};

export const handlePermissonDelete = ({
  databaseUuid,
  targetRecord,
}: {
  databaseUuid: string;
  targetRecord: Record<string, any>;
}) => {
  const state: any = store.getState();
  const database = state.database.database;
  const auth = state.auth.profile;

  const collection = database.find((item: Record<string, any>) => {
    return item.databaseUuid === databaseUuid;
  });

  const permissionEdit = get(collection, 'permission.delete', PERMISSON.ANYONE);

  let message: string = '';
  let status: boolean = false;

  switch (permissionEdit) {
    case PERMISSON.NOBODY:
      status = true;
      message = 'Nobody can delete record';

      break;

    case PERMISSON.LOGGED_USER:
      status = isEmpty(auth) || isNil(auth);
      message = 'Logged in user can delete record';

      break;

    case PERMISSON.RECORD_CREATOR:
      const isCreator =
        get(targetRecord, `creator`, null) === get(auth, 'email', '');

      status = !isCreator;
      message = 'Record creator can delete record';

      break;

    default:
      status = false;
      message = '';

      break;
  }

  return {
    message: message,
    status: status,
  };
};

export const isUpdateVersion = (v1: string, v2: string) => {
  if (typeof v1 !== 'string' || typeof v2 !== 'string') return false;
  let v1parts = v1.split('.');
  let v2parts = v2.split('.');

  if (
    !v1parts.every((x) => /^\d+[A-Za-z]*$/.test(x)) ||
    !v2parts.every((x) => /^\d+[A-Za-z]*$/.test(x))
  ) {
    return false;
  }

  while (v1parts.length < v2parts.length) v1parts.push('0');
  while (v2parts.length < v1parts.length) v2parts.push('0');

  for (var i = 0; i < v1parts.length; ++i) {
    if (v2parts.length == i) {
      return false;
    }

    if (v1parts[i] == v2parts[i]) {
      continue;
    } else if (v1parts[i] > v2parts[i]) {
      return false;
    } else {
      return true;
    }
  }

  if (v1parts.length != v2parts.length) {
    return true;
  }

  return false;
};

export const getVersionPlayStore = async (bundleId: string) => {
  const url = `https://play.google.com/store/apps/details?id=${bundleId}&hl=en`;
  const startToken = 'Current Version';
  const endToken = 'Requires';

  const { data } = await axios.get(url);
  const indexStart = data.indexOf(startToken);
  let value = data.substr(indexStart + startToken.length);
  const indexEnd = value.indexOf(endToken);
  value = value
    .substr(0, indexEnd)
    .replace(/<[^>]+>/g, '')
    .trim();

  return value;
};

export const getVersionAppStore = async (
  appId: string,
  country: string | null
) => {
  const url = !country
    ? `https://itunes.apple.com/lookup?lang=en&id=${appId}`
    : `https://itunes.apple.com/lookup?lang=en&id=${appId}&country=${country}`;

  const res = await axios.get(url);
  if (!res.data || !('results' in res.data)) {
    throw new Error('Unknown error connecting to iTunes.');
  }
  if (!res.data.results.length) {
    const resJP = await axios.get(
      `https://itunes.apple.com/lookup?lang=en&id=${appId}&country=jp`
    );

    if (!res.data.results.length)
      throw new Error('App for this bundle ID not found.');

    const lastestVersionJP: string = res?.data?.results[0]?.version;
    return lastestVersionJP;
  }

  const lastestVersion: string = res?.data?.results[0]?.version;
  return lastestVersion;
};
