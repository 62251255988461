import React, { ComponentProps } from 'react';
import { IconButton as RNIconButton } from 'react-native-paper';
import { StyleSheet, View } from 'react-native';
import { IIconButton } from '@nocode/types';
// import point from '../CustomIcon/point.svg';
import PointIcon from '../CustomIcon';

type Props = ComponentProps<typeof RNIconButton>;

const Icon: React.FC<IIconButton> = ({
  width,
  height,
  attributes,
  onPress,
}) => {
  const page = StyleSheet.create({
    icon: {
      margin: 0,
      width,
      height,
      zIndex: attributes.zIndex,
    },
  });

  const childProps: Props = {
    size: attributes.size,
    icon: attributes.iconName,
    color: attributes.iconColor
      ? attributes.iconColor.trim()
      : attributes.iconColor,
  };

  if (attributes.iconName === 'point') {
    // const pointStyle = StyleSheet.create({
    //   icon: {
    //     width: 24,
    //     height: 24,
    //     tintColor: attributes.iconColor,
    //   },
    // });

    return (
      <View>
        {/* <Image style={pointStyle.icon} source={point} /> */}

        <PointIcon
          width={width}
          height={height}
          fill={attributes.iconColor.trim()}
        />
      </View>
    );
  }

  return (
    <RNIconButton
      style={page.icon}
      {...childProps}
      onPress={() => {
        onPress && onPress();
      }}
    />
  );
};

export default Icon;
