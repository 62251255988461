import React, { useEffect, useState } from 'react';
import { View, Text } from 'react-native';

import QRCode from 'react-qr-code';
import styles from './style';

const AppQrCode = () => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(window.location.href);
  }, []);

  return (
    <View style={styles.qrCodeWrapper}>
      <QRCode size={180} value={value} />
      <View style={styles.qrCodeDescription}>
        <Text>QRコードを読み取ってダウンロードページへアクセスできます。</Text>
      </View>
    </View>
  );
};

export default AppQrCode;
