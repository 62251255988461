import React from 'react';
import { View } from 'react-native';
import Icon from '../AppBar/Icon';
import PointIcon from '../CustomIcon';

type Props = {
  icon: string;
  color?: string;
};

const IconList = ({ icon, color = '#bbb' }: Props) => {
  return (
    <View
      style={{
        height: 50,
        width: 50,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {icon && icon === 'point' ? (
        <PointIcon width={24} height={24} fill={'rgb(187, 187, 187)'} />
      ) : (
        <Icon name={icon} color={color} />
      )}
    </View>
  );
};

export default IconList;
