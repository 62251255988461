import {
  CreateUpdateData,
  DeleteRecordParams,
  ICollection,
  IRecord,
} from '@common/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { find, omit } from 'lodash';

type StatePage = {
  database: ICollection[];
  recordCreated: any;
  currentRecord: Record<string, any>;
  currentLocation: Record<string, any>;
  previousCurrent: Record<string, any>;
  dataSource: any;
  isReloadDatabase: boolean;
};

const initialState: StatePage = {
  database: [],
  recordCreated: {},
  currentRecord: {},
  currentLocation: {},
  previousCurrent: {},
  dataSource: {},
  isReloadDatabase: false,
};

const databaseSlice = createSlice({
  name: 'database',
  initialState: initialState,
  reducers: {
    loadDatabase: (
      state: StatePage,
      action: PayloadAction<Array<ICollection>>
    ) => {
      if (action.payload) {
        state.database = action.payload;
      }
    },
    setCurrentRecord: (
      state: StatePage,
      action: PayloadAction<Record<string, any>>
    ) => {
      const { databaseId, ...rest } = action.payload;
      state.currentRecord = {
        ...omit(state.currentRecord, [`${databaseId}`]),
        [databaseId]: { ...rest },
      };
    },
    setCurrentLocation: (
      state: StatePage,
      action: PayloadAction<Record<string, any>>
    ) => {
      state.currentLocation = action.payload;
    },
    overwriteCurrent: (
      state: StatePage,
      action: PayloadAction<Record<string, any>>
    ) => {
      state.currentRecord = action.payload;
    },
    setPreviousCurrent: (
      state: StatePage,
      action: PayloadAction<Record<string, any>>
    ) => {
      state.previousCurrent = action.payload;
    },
    setCreateRecordAddUpdate: (
      state: StatePage,
      action: PayloadAction<CreateUpdateData>
    ) => {
      const { databaseUuid, record } = action.payload;

      state.recordCreated = {
        ...state.recordCreated,
        [databaseUuid]: record,
      };
    },
    deleteRecordSuccess: (
      state: StatePage,
      action: PayloadAction<DeleteRecordParams>
    ) => {
      const { databaseUuid, recordDeleted } = action.payload;
      state.recordCreated = omit(state.recordCreated, databaseUuid);
      const indexOfElementToRemove = state.dataSource[databaseUuid].findIndex(
        (item: any) => item._id === recordDeleted._id
      );
      if (indexOfElementToRemove >= 0) {
        state.dataSource[databaseUuid].splice(indexOfElementToRemove, 1);
      }
    },
    setDataSource: (
      state: StatePage,
      action: PayloadAction<Array<ICollection>>
    ) => {
      state.dataSource = action.payload;
    },
    updateDataSource: (
      state: StatePage,
      action: PayloadAction<Record<string, any>>
    ) => {
      state.dataSource = {
        ...state.dataSource,
        ...action.payload,
      };
    },
    addItemDataSource: (state: StatePage, action: PayloadAction<any>) => {
      const record: IRecord = action.payload;

      const databaseId = record?.databaseId;

      const recordId = record?._id;

      const dataSource = state.dataSource[databaseId];

      if (databaseId && !find(dataSource, { _id: recordId })) {
        state.dataSource[databaseId].push(record);
      }
    },
    updateItemDataSource: (state: StatePage, action: PayloadAction<any>) => {
      const record: IRecord = action.payload;

      const databaseId = record?.databaseId;

      const recordId = record?._id;

      if (databaseId && recordId) {
        state.dataSource[databaseId] = [
          ...state.dataSource[databaseId].filter(
            (item: IRecord) => item._id !== recordId
          ),
          record,
        ];
      }
    },
    updateTable: (state: StatePage, action: PayloadAction<any>) => {
      state.dataSource[action.payload.tableId] = action.payload.data;
    },
    setReloadStatus: (state: StatePage, action: PayloadAction<any>) => {
      state.isReloadDatabase = action.payload;
    },
  },
});

export const {
  loadDatabase,
  setCreateRecordAddUpdate,
  deleteRecordSuccess,
  setCurrentRecord,
  setCurrentLocation,
  setDataSource,
  addItemDataSource,
  updateItemDataSource,
  setPreviousCurrent,
  overwriteCurrent,
  updateDataSource,
  updateTable,
  setReloadStatus,
} = databaseSlice.actions;
export default databaseSlice.reducer;
