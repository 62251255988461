import { IAction } from '@common/types/';
import { getTextBinding } from '@common/utils/handleBinding';
import { Linking, Platform, Alert } from 'react-native';
import { ActionResponse } from '../excuteAction';
import { InAppBrowser } from 'react-native-inappbrowser-reborn';
import { isEmpty } from 'lodash';

const executeWebLink: (
  action: IAction,
  currentRecord?: Record<string, any>,
  itemIndex?: number,
  listSchemeLink?: any
) => Promise<ActionResponse> = async (
  action: IAction,
  currentRecord?: Record<string, any>,
  itemIndex?: number,
  listSchemeLink?: any
) => {
  try {
    const url = getTextBinding(
      action.options.url,
      currentRecord,
      undefined,
      itemIndex
    );

    if (typeof url !== 'string')
      return {
        status: 'FAILED',
      };

    switch (action.type) {
      case 'tel':
        if (url.trim()) {
          const isSupported = await Linking.canOpenURL(`tel://${url.trim()}`);
          if (isSupported) {
            Platform.OS === 'web'
              ? window.open(`tel://${url.trim()}`, '_top')
              : await Linking.openURL(`tel://${url.trim()}`);
            return {
              status: 'SUCCEED',
            };
          }
        }
        break;

      case 'sms':
        if (url.trim()) {
          const isSupported = await Linking.canOpenURL(`sms:${url.trim()}`);
          if (isSupported) {
            Platform.OS === 'web'
              ? window.open(`sms:${url.trim()}`, '_top')
              : await Linking.openURL(`sms:${url.trim()}`);
            return {
              status: 'SUCCEED',
            };
          }
        }
        break;

      case 'mailto':
        if (url.trim()) {
          const isSupported = await Linking.canOpenURL(`mailto:${url.trim()}`);
          if (isSupported) {
            Platform.OS === 'web'
              ? window.open(`mailto:${url.trim()}`, '_top')
              : await Linking.openURL(`mailto:${url.trim()}`);
            return {
              status: 'SUCCEED',
            };
          }
        }
        break;

      case 'deeplink':
        if (url.trim()) {
          const pattern = /^((http|https|www):\/\/)/;
          const patternScheme = /^(.*?)\:\/\//;
          const scheme = url.split(patternScheme)[1];
          const isScheme =
            !isEmpty(listSchemeLink) && listSchemeLink.includes(scheme);
          const isContains = pattern.test(url);
          if (isContains || !isScheme) {
            Alert.alert(
              'Something went wrong',
              `Something went wrong when open this URL: ${url.trim()} `
            );
            break;
          }
          const isSupported = await Linking.canOpenURL(`${url.trim()}`);
          if (isSupported && !isContains && isScheme) {
            await Linking.openURL(`${url.trim()}`);
            return {
              status: 'SUCCEED',
            };
          } else {
            Alert.alert(
              'Something went wrong',
              `Don't know how to open this URL: ${url.trim()}`
            );
          }
        } else {
          Alert.alert('Something went wrong', "Don't have any url to open");
        }
        break;
      default:
        const expression =
          /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
        const regex = new RegExp(expression);

        const pattern = /^((http|https):\/\/)/;
        const isContains = pattern.test(url);
        let _url = '';
        if (url) {
          if (isContains && url && url.match(regex)) {
            _url = `${url}`;
          } else {
            const convertUrl = 'http://' + url;
            _url = convertUrl;
          }

          if (Platform.OS === 'web') {
            await Linking.openURL(_url);
          } else {
            if (await InAppBrowser.isAvailable()) {
              InAppBrowser.close();
            }
            await InAppBrowser.open(_url);
          }

          return {
            status: 'SUCCEED',
          };
        }
        break;
    }

    return {
      status: 'FAILED',
    };
  } catch (err) {
    console.log('err', err);

    return {
      status: 'FAILED',
    };
  }
};

export default executeWebLink;
