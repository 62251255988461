import { IMap } from '@common/types/element';
import {
  GoogleMap,
  Marker as MakerWeb,
  MarkerClusterer,
} from '@react-google-maps/api';
import axios from 'axios';
import {
  chunk,
  compact,
  filter,
  get,
  isEqual,
  isNil,
  map,
  pick,
  uniqBy,
} from 'lodash';
import queryString from 'query-string';
import React, {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import * as rax from 'retry-axios';
import { DEFAULT_CENTER, DEFAULT_DISTANCE, DEFAULT_ZOOM } from './constant';
import hybrid from './hybrid.png';
import roadmap from './roadmap.png';
import satellite from './satellite.png';
import createStyles from './style';
import terrain from './terrain.png';
import currentLocationIcon from './currentLocation.png';

const isDevelopment = process.env.REACT_APP_ENV === 'development';

const mapImgInCanvas = {
  roadmap,
  satellite,
  terrain,
  hybrid,
};

declare const window: any;
type MarkerType = {
  lat: number;
  lng: number;
  _id?: string;
};

const getCurrentLocationStatus = (attrs: any) => {
  const makerType = get<'simple' | 'multiple'>(attrs, 'markerType', 'multiple');

  let path = 'markers.currentLocation';

  if (makerType === 'simple') {
    path = 'marker.currentLocation';
  }

  return get(attrs, path);
};

const isJsonString = (str: any) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const Map: React.FC<IMap> = (props) => {
  const cusAxios = axios.create();
  cusAxios.defaults.raxConfig = {
    instance: cusAxios,
  };
  rax.attach(cusAxios);

  const { width, height, onPress } = props;
  const [markers, setMarkers] = useState<Array<MarkerType>>([]);
  const [currentLocation, setCurrentLocation] = useState<MarkerType>(Object);
  const [mapState, setMapState] = useState<any>(null);
  const search = queryString.parse(window?.location?.search);
  const target = search?.target;
  const mapType = props?.attributes?.style?.mapStyle || 'roadmap';
  /////canvas
  const mapImage = mapImgInCanvas[mapType];
  /////preview
  const styles = createStyles(props);
  const customMapStyle = props?.attributes?.style?.customStyle;
  const apiKey = props?.attributes?.apiKey;
  const loadScriptMap = () => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
    script.id = apiKey;
    script.onload = () => props.loadScriptMap && props.loadScriptMap();

    document.head.appendChild(script);
  };

  useEffect(() => {
    const scriptMap = document.getElementById(apiKey) as HTMLScriptElement;

    apiKey && !scriptMap && !isNil(props.googleMapisloaded) && loadScriptMap();
  }, []);

  const showCurrentUserLocation = useMemo(
    () => getCurrentLocationStatus(props.attributes),
    [props.attributes]
  );
  const centerLocation = useMemo(() => {
    if (showCurrentUserLocation && currentLocation) {
      return get(compact([currentLocation, ...markers]), '0', DEFAULT_CENTER);
    } else {
      return get(compact(markers), '0', DEFAULT_CENTER);
    }
  }, [markers, currentLocation]);

  const requestBody = useMemo(() => {
    const addressMarker = chunk(
      compact(map(props.dataBinding, 'markerAddress')),
      50
    );
    return (
      target &&
      map(addressMarker, (address) => ({
        apiKey,
        addresses: address,
      }))
    );
  }, [apiKey, target, props.dataBinding]);

  const postAddressMarker = async (requestBody: any) => {
    const { data } = await cusAxios.post(
      isDevelopment
        ? 'http://13.115.182.81/maps'
        : 'https://api.click.dev/maps',
      requestBody,
      {
        raxConfig: {
          shouldRetry: (err) => {
            const cfg = rax.getConfig(err);
            if (
              typeof cfg?.currentRetryAttempt === 'number' &&
              typeof cfg.retry === 'number'
            ) {
              if (cfg?.currentRetryAttempt >= cfg.retry) return false;
              return true;
            }
            return false;
          },
        },
      }
    );
    return data;
  };
  const handleCompareMarker = (source: any) => {
    if (!source) return;

    const valueBinding =
      props.getFieldValue && props.getFieldValue(source, null, false);

    if (!valueBinding) return;
    else {
      return valueBinding;
    }
  };

  const handledLatitude = handleCompareMarker(props.attributes.marker?.markerY);

  const handledLongitude = handleCompareMarker(
    props.attributes.marker?.markerX
  );

  const handledDistance =
    props.getFieldValue &&
    props.getFieldValue(props.attributes.markers?.distanceSearch, null, false);
  const distanceToSearch =
    handledDistance < 0 ? DEFAULT_DISTANCE : Number(handledDistance);

  const getListAddressMarker = async () => {
    if (
      !requestBody ||
      !requestBody.length ||
      !get(requestBody, '[0].addresses')?.length ||
      !get(requestBody, '[0].apiKey')
    ) {
      if (!showCurrentUserLocation) {
        setMarkers([DEFAULT_CENTER]);
      }
      return;
    }
    let data: any[] = [];

    for (const requestBD of requestBody) {
      const dataMarker = await postAddressMarker(requestBD);
      data.push(dataMarker);
    }
    const availableData = filter(props.dataBinding, 'markerAddress').map(
      (record, idx) => ({
        ...get(data.flat(), idx, {}),
        ...pick(record, ['_id']),
      })
    );
    const listMarkers = uniqBy(availableData, 'lat' && 'lng').filter(
      (marker) => !isNil(marker.lat) && !isNil(marker.lng)
    );
    setMarkers(listMarkers);
  };
  const convertLat = (num: number) => {
    const remainder = num % 180;
    if (remainder > 90) {
      return remainder - 180;
    } else if (remainder < -90) {
      return remainder + 180;
    } else {
      return remainder;
    }
  };
  const getMarkers = useCallback(async () => {
    if (props.attributes.markerType === 'simple') {
      //simple
      if (props.attributes.marker.markerTypeOfPin === 'address') {
        getListAddressMarker();
      } else {
        const geometryData = {
          lng: Number(handledLongitude),
          lat: convertLat(Number(handledLatitude)),
        };

        if (handledLongitude === undefined || handledLatitude === undefined)
          return;
        setMarkers([geometryData]);
      }
    } else {
      //multi
      if (props.attributes.markers.markerTypeOfPin === 'address') {
        getListAddressMarker();
      } else {
        const data = filter(props.dataBinding, 'markerGeometry').map(
          (record) => ({
            ...pick(record.markerGeometry, ['lat', 'lng', '_id']),
          })
        );
        const availableData = data
          .filter((marker) => !isNil(marker.lat) && !isNil(marker.lng))
          .map((marker) => ({
            lng: Number(marker.lng),
            lat: convertLat(Number(marker.lat)),
            _id: marker?._id,
          }));
        const listMarkers = uniqBy(availableData, 'lat' && 'lng');
        setMarkers(listMarkers);
      }
    }
  }, [requestBody, props.dataBinding, props.attributes.markerType]);

  const handleClick = useCallback(
    (itemId: string) => () => {
      return onPress && itemId && onPress('', { itemId });
    },
    [onPress]
  );

  const getDistanceFromCurrent = (mk1: MarkerType, mk2: MarkerType) => {
    const Radius = 6371;
    const rLat1 = mk1.lat * (Math.PI / 180);
    const rLat2 = mk2.lat * (Math.PI / 180);
    const diffLat = rLat2 - rLat1;
    const diffLng = (mk2.lng - mk1.lng) * (Math.PI / 180);

    const distance =
      2 *
      Radius *
      Math.asin(
        Math.sqrt(
          Math.sin(diffLat / 2) * Math.sin(diffLat / 2) +
            Math.cos(rLat1) *
              Math.cos(rLat2) *
              Math.sin(diffLng / 2) *
              Math.sin(diffLng / 2)
        )
      );
    return distance;
  };
  const closeMarkers = markers
    .map((marker: any) => ({
      ...marker,
      dis: getDistanceFromCurrent(currentLocation, marker),
    }))
    .filter((marker) => marker.dis <= distanceToSearch);

  const imgCluster = {
    imagePath:
      'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
  };

  const googleMapMemo = useMemo(
    () => (
      <GoogleMap
        mapContainerStyle={{
          height,
          width,
        }}
        options={{
          zoom: DEFAULT_ZOOM,
          center: centerLocation,
          panControl: true,
          disableDefaultUI: true,
          zoomControl: true,
          mapTypeId: mapType,
          styles: JSON.parse(
            isJsonString(customMapStyle) ? customMapStyle : '[]'
          ),
        }}
        onLoad={(map) => {
          setMapState(map);
        }}
      >
        {showCurrentUserLocation && mapState && (
          <TouchableOpacity
            style={styles.currentLocationButton}
            onPress={() => {
              const Bounds = new window.google.maps.LatLngBounds(
                currentLocation
              );
              mapState?.fitBounds(Bounds);
              mapState?.setZoom(DEFAULT_ZOOM);
            }}
          >
            <Image
              style={{ width: '28px', height: '28px' }}
              source={{
                uri: 'https://cdn2.iconfinder.com/data/icons/boxicons-regular-vol-2/24/bx-current-location-256.png',
              }}
            />
          </TouchableOpacity>
        )}

        {markers.length ? (
          <MarkerClusterer options={imgCluster}>
            {(clusterer) => {
              return (
                <>
                  {showCurrentUserLocation && (
                    <MakerWeb
                      position={currentLocation}
                      icon={currentLocationIcon}
                      clusterer={clusterer}
                    />
                  )}
                  {markers.map((marker, index) => (
                    <MakerWeb
                      clusterer={clusterer}
                      key={index}
                      position={marker}
                      onClick={handleClick(marker._id as string)}
                    />
                  ))}
                </>
              );
            }}
          </MarkerClusterer>
        ) : !showCurrentUserLocation ? (
          <MakerWeb position={DEFAULT_CENTER} />
        ) : (
          <MakerWeb position={currentLocation} icon={currentLocationIcon} />
        )}
      </GoogleMap>
    ),
    [centerLocation, mapType, customMapStyle, markers, mapState]
  );
  const loadScriptMemo = useMemo(
    () =>
      props.googleMapisloaded || window?.google?.maps ? (
        googleMapMemo
      ) : (
        <>Loading...</>
      ),
    [googleMapMemo, props.googleMapisloaded]
  );

  useLayoutEffect(() => {
    if (target) {
      const getCurrentUserLocation = () => {
        if (!props.currentLocation) return;
        setCurrentLocation(props.currentLocation);
      };
      if (showCurrentUserLocation) {
        getCurrentUserLocation();
      }
      getMarkers();
    }
  }, [props.dataBinding]);

  useLayoutEffect(() => {
    if (mapState) {
      const Bounds = new window.google.maps.LatLngBounds();

      if (showCurrentUserLocation) {
        Bounds.extend(currentLocation);
        if (
          props.attributes.markerType === 'multiple' &&
          closeMarkers.length > 0
        ) {
          closeMarkers.map((closeMarker) => {
            Bounds.extend(closeMarker);
          });
          mapState.fitBounds(Bounds);
        } else if (
          markers.length > 0 &&
          props.attributes.markerType === 'simple'
        ) {
          markers.map((marker) => {
            Bounds.extend(marker);
          });
          mapState.fitBounds(Bounds);
        } else {
          return;
        }
      } else if (!showCurrentUserLocation && markers.length > 1) {
        markers.map((marker) => {
          Bounds.extend(marker);
        });
        mapState.fitBounds(Bounds);
      } else {
        return;
      }
    }
  }, [mapState, markers]);

  if (target) {
    //// preview
    return (
      <View style={styles.container}>
        {!apiKey ? (
          <View style={styles.map}>
            <View style={styles.emptyKeyWrap}>
              <Text style={styles.emptyKeyContent}>
                API Key is not set.....
              </Text>
            </View>
          </View>
        ) : (
          loadScriptMemo
        )}
      </View>
    );
  } else {
    //// canvas
    return (
      <Image
        style={{
          width: props.width,
          height: props.height,
        }}
        source={mapImage || roadmap}
      />
    );
  }
};

const paths = [
  'dataBinding',
  'attributes.style.mapStyle',
  'width',
  'height',
  'googleMapisloaded',
];

export default memo(Map, (prev, next) => {
  return isEqual(pick(prev, paths), pick(next, paths));
});
